import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MicIcon from 'src/icons/MicIcon'
import MicOffIcon from 'src/icons/MicOffIcon'
import MaterialMicOffIcon from '@material-ui/icons/MicOff'
import MaterialMicIcon from '@material-ui/icons/Mic'

import useLocalAudioToggle from 'src/hooks/useLocalAudioToggle/useLocalAudioToggle'
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'

interface ToggleAudioButtonProps {
  icon?: boolean
  disabled?: boolean
  fullWidth?: boolean
  className?: string
  customIcon?: boolean
  variant?: 'text' | 'outlined' | 'contained' | undefined
}

export const MUTE_TEXT = 'Mute your microphone'
export const UNMUTE_TEXT = 'Unmute your microphone'

export const MUTE_BUTTON_TEXT = 'Mute'
export const UNMUTE_BUTTON_TEXT = 'Unmute'
export const NO_AUDIO_BUTTON_TEXT = 'No Audio'

export default function ToggleAudioButton({
  icon,
  disabled,
  fullWidth,
  className,
  variant,
  customIcon = false,
}: ToggleAudioButtonProps) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle()
  const { localTracks } = useVideoContext()
  const hasAudioTrack = localTracks.some((track) => track.kind === 'audio')

  const micOn = customIcon ? <MaterialMicIcon /> : <MicIcon />
  const micOff = customIcon ? <MaterialMicOffIcon /> : <MicOffIcon />

  let tooltipMessage = ''
  if (customIcon) {
    tooltipMessage = isAudioEnabled ? MUTE_TEXT : UNMUTE_TEXT
  }

  let buttonText = MUTE_BUTTON_TEXT
  if (!isAudioEnabled) {
    buttonText = UNMUTE_BUTTON_TEXT
  }
  if (!hasAudioTrack) {
    buttonText = NO_AUDIO_BUTTON_TEXT
  }

  return (
    <Tooltip
      title={tooltipMessage}
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{ cursor: 'pointer' }}
    >
      <span>
        {icon ? (
          <IconButton
            size="medium"
            color="primary"
            aria-label="microphone"
            onClick={toggleAudioEnabled}
            disabled={!hasAudioTrack || disabled}
          >
            {isAudioEnabled ? micOn : micOff}
          </IconButton>
        ) : (
          <Button
            className={className}
            onClick={toggleAudioEnabled}
            disabled={!hasAudioTrack || disabled}
            startIcon={isAudioEnabled ? micOn : micOff}
            variant={variant}
            fullWidth={fullWidth}
            data-cy-audio-toggle
          >
            {buttonText}
          </Button>
        )}
      </span>
    </Tooltip>
  )
}
