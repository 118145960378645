import { useState } from 'react'
import { IconButton } from '@material-ui/core'
import ReportIcon from '@material-ui/icons/Report'
import ReportDialog from 'src/components/ReportDialog/ReportDialog'

export default function ReportButton() {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <IconButton
        aria-label="help"
        color='secondary'
        onClick={() => setOpen(true)}
      >
        <ReportIcon />
      </IconButton>

      <ReportDialog open={open} onClose={() => setOpen(false)} />
    </div>
  )
}
