// This function is used to provide error messages to the user that are
// different than the error messages provided by the SDK.
export default function enhanceMessage(message = '', code?: number) {
  // If the issue is the camera permissions being blocked
  if (message === 'Permission denied') {
    return 'Your camera or microphone has been blocked. Please allow Cloud Present access to you camera and microphone and reload the page.'
  }

  switch (code) {
    case 20101: // Invalid token error
      return 'Please make sure you are using the correct credentials.'
    case 53001: // Internet error
      return 'Please make sure you are connected to the internet and try again.'
    default:
      return message
  }
}
