import { useEffect, useState } from 'react'
import {
  makeStyles,
  Grid,
  Button,
  Theme,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview'
import { useAppState } from 'src/state'
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'
import useLocalVideoToggle from 'src/hooks/useLocalVideoToggle/useLocalVideoToggle'
import useCheckForHostParticipant from 'src/hooks/useCheckForHostParticipant/useCheckForHostParticipant'
import useLocalAudioToggle from 'src/hooks/useLocalAudioToggle/useLocalAudioToggle'
import { MAX_GROUP_PARTICIPANTS } from 'src/constants/global'
import { canJoinGroupRecording } from '@michaelprichardson/cloudpresent-core'
import AudioInputList from 'src/components/DeviceSelection/AudioInputList/AudioInputList'
import AudioOutputList from 'src/components/DeviceSelection/AudioOutputList/AudioOutputList'
import VideoInputList from 'src/components/DeviceSelection/VideoInputList/VideoInputList'
import { addToParticipants, getGroupParticipants } from '@michaelprichardson/cloudpresent-core/lib/platform/groupRecording'
import type { Participant } from '@michaelprichardson/cloudpresent-core'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 500,
    padding: theme.spacing(1),
  },
  localPreviewContainer: {
    paddingRight: theme.spacing(2),
  },
  gutterBottom: {
    marginBottom: theme.spacing(1),
  },
  deviceButton: {
    width: '100%',
    border: '1px solid #aaa',
    marginBottom: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(1),
    display: 'flex',
  },
}))

interface DeviceSelectionScreenProps {
  name: string
  participant?: Participant
  eventId: string
  isGuest: boolean
  isGroup: boolean
}

export default function DeviceSelectionScreen({
  name,
  participant,
  isGuest,
  eventId,
  isGroup,
}: DeviceSelectionScreenProps) {
  const classes = useStyles()
  const [canShowHostJoinedModal, setCanShowHostJoinedModal] = useState(false)
  const [showHostJoinedModal, setShowHostJoinedModal] = useState(false)
  const [canJoin, setCanJoin] = useState(false)
  const [joinError, setJoinError] = useState('')
  const [showJoinErrorModal, setJoinErrorModal] = useState(false)
  const { getToken, groupRecording, uploadId } = useAppState()
  const { isParticipant, hostPresent, sessionLocked } =
    useCheckForHostParticipant(isGroup, participant)
  const { connect, isAcquiringLocalTracks } = useVideoContext()
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle()
  const [isAudioEnabled] = useLocalAudioToggle()
  const isHost = !isParticipant
  const hostAlreadyPresent = isParticipant && hostPresent && uploadId
  const allowJoin =
    isVideoEnabled &&
    isAudioEnabled &&
    (isHost || hostAlreadyPresent || !isGroup) &&
    !sessionLocked &&
    canJoin &&
    Boolean(participant)

  useEffect(() => {
    toggleVideoEnabled()
  }, [])

  useEffect(() => {
    const getCurrentParticipants = async () => {
      const { allow, error } = await canJoinGroupRecording(
        groupRecording.eventId,
        groupRecording.id,
        participant
      )
      setCanJoin(allow)
      setJoinError(error || '')
    }

    if (isGroup && groupRecording && participant) {
      getCurrentParticipants()
    } else {
      setCanJoin(true)
    }
  }, [isGroup, groupRecording, participant])

  useEffect(() => {
    if (!hostPresent && isGroup) {
      const timer = setTimeout(() => {
        if (!hostPresent) {
          setCanShowHostJoinedModal(true)
        }
      }, 10000)

      return () => clearTimeout(timer)
    }
    return
  }, [!hostPresent])

  useEffect(() => {
    if (canShowHostJoinedModal) {
      setShowHostJoinedModal(true)
    }
  }, [hostPresent])

  const handleJoin = async () => {
    setJoinErrorModal(false)
    if (!participant) {
      return
    }

    if (allowJoin && isGroup) {
      const currentParticipants = await getGroupParticipants(
        groupRecording.eventId,
        groupRecording.id
      )
      // Check if the group already has more that the allowed participants
      // or if it already contains the current participant
      const participantIds = new Set(Object.keys(currentParticipants))
      if (participantIds.size >= MAX_GROUP_PARTICIPANTS) {
        setJoinError(
          `Only a maximum of ${MAX_GROUP_PARTICIPANTS} participants are allowed in the session`
        )
        setJoinErrorModal(true)
        setShowHostJoinedModal(false)
        setCanJoin(false)
      } else if (participantIds.has(participant.id)) {
        setJoinError(
          `This email address (${participant.email}) is already being used in the session`
        )
        setJoinErrorModal(true)
        setShowHostJoinedModal(false)
        setCanJoin(false)
      } else {
        const participantId = participant.id
        const result = await getToken(
          groupRecording.id,
          participant.email,
          participantId,
          participant.name,
          isGroup ? isGuest : false,
          eventId,
          uploadId
        )

        await addToParticipants(
          groupRecording.eventId,
          groupRecording.id,
          participantId,
          participant.name
        )
        await connect(result.token)
      }
    } else {
      const participantId = participant.id
      const result = await getToken(
        groupRecording?.id,
        participant.email,
        participantId,
        participant.name,
        isGuest,
        eventId,
        uploadId
      )

      await connect(result.token)
    }
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={8} style={{ width: '100%' }}>
        <Card className={classes.root} elevation={0}>
          <CardHeader title={`Setup Audio and Video`} />
          <CardContent>
            <Grid container justifyContent="center">
              <Grid item md={8} sm={8} xs={8}>
                <div className={classes.localPreviewContainer}>
                  <LocalVideoPreview identity={name} />
                </div>
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                  style={{ height: '100%', paddingTop: '8px' }}
                >
                  <div>
                    {!isAcquiringLocalTracks && (
                      <>
                        <VideoInputList />
                        <AudioInputList />
                        <AudioOutputList />
                      </>
                    )}

                    {isParticipant && !hostPresent && (
                      <Typography
                        variant="body1"
                        color="secondary"
                        style={{ textAlign: 'center', width: '100%' }}
                      >
                        Waiting for the host to join
                      </Typography>
                    )}
                    {isParticipant && hostPresent && canJoin && (
                      <Typography
                        variant="body1"
                        color="secondary"
                        style={{
                          textAlign: 'center',
                          width: '100%',
                          color: '#2FA149',
                        }}
                      >
                        You can now join the session
                      </Typography>
                    )}
                    {sessionLocked && (
                      <Typography
                        variant="body1"
                        color="secondary"
                        style={{ textAlign: 'center', width: '100%' }}
                      >
                        The session is currently locked
                      </Typography>
                    )}
                    {!canJoin && (
                      <Typography
                        variant="body1"
                        color="secondary"
                        style={{ textAlign: 'center', width: '100%' }}
                      >
                        {joinError}
                      </Typography>
                    )}
                  </div>
                  <div className={classes.buttons}>
                    <Button
                      variant="contained"
                      color="primary"
                      data-cy-join-now
                      onClick={handleJoin}
                      disabled={!allowJoin}
                      style={{ marginLeft: 'auto' }}
                    >
                      {groupRecording ? 'Join' : 'Start'}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={showHostJoinedModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Host Joined</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The host has joined. You can now join the session
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleJoin}
            disabled={!allowJoin}
            color="primary"
            variant="contained"
          >
            Join
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showJoinErrorModal}
        onClose={setJoinErrorModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Error Joining</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {joinError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setJoinErrorModal(false)}
            color="primary"
            variant="outlined"
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  )
}
