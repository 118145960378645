import Typography from '@material-ui/core/Typography'
import useUploadingContext from 'src/hooks/useUploadingContext/useUploadingContext'

export default function RecordTimer() {
  const { isRecording, timer } = useUploadingContext()

  return (
    <Typography variant="inherit" color={isRecording ? 'secondary' : 'initial'}>
      <b>{timer}</b>
    </Typography>
  )
}
