export const HOME = '/'
export const HOW = '/how-it-works'
export const CONTACT_US = '/contact-us'
export const PRIVACY = '/privacy'

export const LOGIN = '/login'
export const REGISTER = '/register'
export const ACCOUNT = '/account'
export const PASSWORD_FORGET = '/password-forget'

export const RECORD = '/record/:eventSlug'
export const RECORDING = '/record/:company/:event'
export const GROUP_RECORDING = '/group-record/:company/:event/:topic'
export const NOT_FOUND = '/not-found'

export const ADMIN_DASHBOARD = '/admin/dashboard'
export const ADMIN_EVENTS = '/admin/events'
export const ADMIN_EVENT = '/admin/event/:eventId'
export const ADMIN_EVENT_RECORDINGS = '/admin/event/:eventId/recordings'
export const ADMIN_EVENT_GROUP_RECORDINGS =
  '/admin/event/:eventId/group-recordings'
export const ADMIN_EVENT_GROUP_RECORDING_SESSIONS =
  '/admin/event/:eventId/group-recordings/:groupId'
export const ADMIN_EVENT_GROUP_RECORDING_SESSION =
  '/admin/event/:eventId/group-recordings/:groupId/:uploadId'
export const ADMIN_CONTACT_US = '/admin/contact-us'
