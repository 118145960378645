import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_VIDEO_INPUT_KEY,
} from 'src/constants/video'
import {
  FormControl,
  MenuItem,
  Typography,
  Select,
  FormHelperText,
} from '@material-ui/core'
import { LocalVideoTrack } from 'twilio-video'
import { makeStyles } from '@material-ui/core/styles'
import VideoTrack from '../../VideoTrack/VideoTrack'
import useMediaStreamTrack from 'src/hooks/useMediaStreamTrack/useMediaStreamTrack'
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'
import { useVideoInputDevices } from 'src/hooks/deviceHooks/deviceHooks'

const useStyles = makeStyles({
  preview: {
    width: '300px',
    maxHeight: '200px',
    margin: '0.5em auto',
    '& video': {
      maxHeight: '200px',
    },
  },
})

export default function VideoInputList() {
  const classes = useStyles()
  const videoInputDevices = useVideoInputDevices()
  const { localTracks, updateVideoTrackId } = useVideoContext()

  const localVideoTrack = localTracks.find(
    (track) => track.kind === 'video'
  ) as LocalVideoTrack
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack)
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId

  function replaceTrack(newDeviceId: string) {
    updateVideoTrackId(newDeviceId)
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId)

    localVideoTrack.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId },
    })
  }

  return (
    <div>
      {localVideoTrack && (
        <div className={classes.preview}>
          <VideoTrack isLocal track={localVideoTrack} />
        </div>
      )}
      {videoInputDevices.length > 1 ? (
        <FormControl fullWidth>
          <Typography variant="subtitle2" gutterBottom>
            Video Input
          </Typography>
          <Select
            onChange={(e) => replaceTrack(e.target.value as string)}
            value={localVideoInputDeviceId || ''}
            variant="outlined"
          >
            {videoInputDevices.map((device) => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            Select the camera you would like to use
          </FormHelperText>
        </FormControl>
      ) : (
        <>
          <Typography variant="subtitle2" gutterBottom>
            Video Input
          </Typography>
          <Typography>
            {localVideoTrack?.mediaStreamTrack.label || 'No Local Video'}
          </Typography>
        </>
      )}
    </div>
  )
}
