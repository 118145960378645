import { useBeforeunload } from 'react-beforeunload'
import { Room } from 'twilio-video'

export default function useClosingTabEvent(showPrompt: boolean, room: Room) {
  useBeforeunload((event: BeforeUnloadEvent) => {
    if (showPrompt) {
      event.preventDefault()
    } else {
      room.disconnect()
    }
  })
}
