import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import useUploadingContext from 'src/hooks/useUploadingContext/useUploadingContext'

export const NO_RECORDINGS_PREVIEW_TEXT =
  'You need a recording to view a preview'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.black.main,
      backgroundColor: theme.palette.white.main,
      '&:hover': {
        backgroundColor: '#AAAAAA',
        borderColor: '#AAAAAA',
      },
    },
  })
)

interface PreviewVideoButtonProps {
  icon?: boolean
  disabled?: boolean
  fullWidth?: boolean
}

export default function PreviewVideoButton({
  icon,
  disabled,
  fullWidth,
}: PreviewVideoButtonProps) {
  const classes = useStyles()
  const { haveRecordings, togglePreviewDialog } = useUploadingContext()

  const isDisabled = !haveRecordings

  let tooltipMessage = ''
  if (!haveRecordings) {
    tooltipMessage = NO_RECORDINGS_PREVIEW_TEXT
  }

  return (
    <Tooltip
      title={tooltipMessage}
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      }}
    >
      <span>
        {icon ? (
          <IconButton
            size="medium"
            color="primary"
            aria-label="preview recording"
            onClick={() => togglePreviewDialog()}
            disabled={isDisabled}
          >
            <PlayArrowIcon />
          </IconButton>
        ) : (
          <Button
            className={classes.button}
            color="primary"
            variant="outlined"
            onClick={() => togglePreviewDialog()}
            disabled={isDisabled}
            startIcon={<PlayArrowIcon />}
            fullWidth={fullWidth}
          >
            Preview Recording
          </Button>
        )}
      </span>
    </Tooltip>
  )
}
