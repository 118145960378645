import { useState, useCallback, useRef } from 'react'
import { Track, Room, LogLevels } from 'twilio-video'
import { SCREEN_RECORDING_OPTIONS } from '../../../constants/global'
import { ErrorCallback } from '../../../constants/types'

interface MediaStreamTrackPublishOptions {
  name?: string
  priority: Track.Priority
  logLevel: LogLevels
}

export default function useScreenShareToggle(
  isGroup: boolean,
  room: Room,
  onError: ErrorCallback
) {
  const [isSharingScreen, setSharingScreen] = useState(false)
  const [screenShareStream, setScreenShareStream] = useState<MediaStream>()
  const stopScreenShareRef = useRef<() => void>(null!)

  const shareScreen = useCallback(() => {
    window.navigator.mediaDevices
      // @ts-ignore
      .getDisplayMedia(SCREEN_RECORDING_OPTIONS)
      .then((stream: MediaStream) => {
        setScreenShareStream(stream)
        const track = stream.getTracks()[0]

        // If it is a group recording then publish the screen share
        // All video tracks are published with 'low' priority. This works because the video
        // track that is displayed in the 'MainParticipant' component will have it's priority
        // set to 'high' via track.setPriority()
        room.localParticipant
          .publishTrack(track, {
            name: `screen-${new Date().getMilliseconds()}`, // Tracks can be named to easily find them later
            priority: 'high', // Priority is set to high by the subscriber when the video track is rendered
          } as MediaStreamTrackPublishOptions)
          .then((trackPublication) => {
            stopScreenShareRef.current = () => {
              room.localParticipant.unpublishTrack(track)
              // TODO: remove this if the SDK is updated to emit this event
              room.localParticipant.emit('trackUnpublished', trackPublication)
              track.stop()
              setSharingScreen(false)
              setScreenShareStream(undefined)
            }

            track.onended = stopScreenShareRef.current
            setSharingScreen(true)
          })
          .catch(onError)

        // This will be used if the user clicks the stop share from the browser
        track.addEventListener('ended', async () => {
          stopScreenShareRef.current()
        })
      })
      .catch((error: any) => {
        // Don't display an error if the user closes the screen share dialog
        if (error.name !== 'AbortError' && error.name !== 'NotAllowedError') {
          onError(error)
        }
      })
  }, [room, onError])

  const toggleScreenShare = useCallback(() => {
    !isSharingScreen ? shareScreen() : stopScreenShareRef.current()
  }, [isSharingScreen, shareScreen, stopScreenShareRef])

  return {
    isSharingScreen,
    screenShareStream,
    toggleScreenShare,
  }
}
