import { Logger } from '@michaelprichardson/cloudpresent-core'
import { GaussianBlurBackgroundProcessor, VirtualBackgroundProcessor } from '@twilio/video-processors'
import { useState, useEffect, useCallback } from 'react'
// import Video, { LocalAudioTrack, LocalVideoTrack } from 'twilio-video'
import { RECORDING_TIME_SLICE } from 'src/constants/global'
import { IVideoTrack } from 'src/constants/types'
import usePublications from 'src/hooks/usePublications/usePublications'
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'
import { useAppState } from 'src/state'

import { AudioTrack as IAudioTrack } from 'twilio-video'

export default function useMediaRecorder(isRecording: boolean) {
  const category = 'useMediaRecorder'
  const [cameraChunks, setCameraChunks] = useState<Blob[]>([])
  const [cameraBlob, setCameraBlob] = useState<Blob>()

  const [screenChunks, setScreenChunks] = useState<Blob[]>([])
  const [screenBlob, setScreenBlob] = useState<Blob>()

  const [hadBlob, setHadBlob] = useState(false)

  const [cameraMediaRecorder, setCameraMediaRecorder] =
    useState<MediaRecorder>()
  const [screenMediaRecorder, setScreenMediaRecorder] =
    useState<MediaRecorder>()

  const[videoProcessor, setVideoProcessor] = useState<
    GaussianBlurBackgroundProcessor | VirtualBackgroundProcessor
  >()

  const { participant } = useAppState()

  const {
    room: { localParticipant },
    screenShareStream,
    virtualBackground,
  } = useVideoContext()
  const publications = usePublications(localParticipant)

  useEffect(() => {
    async function setupRecorders() {
      const cameraPublication = publications.find(
        (p) => p.kind === 'video' && p.trackName.includes('camera')
      )
      const audioPublication = publications.find((p) => p.kind === 'audio')

      if (cameraPublication && audioPublication && !participant.isGuest) {
        const videoTrack = cameraPublication.track as IVideoTrack
        const audioTrack = audioPublication.track as IAudioTrack

        if (videoProcessor) {
          Logger.log(category, 'Removing video processor', videoProcessor)
          videoTrack.removeProcessor(videoProcessor)
          setVideoProcessor(undefined)
        }

        // TODO: Need to sort out the different setups normal, blur, and background
        if (virtualBackground && virtualBackground !== 'none') {
          if (virtualBackground.startsWith('blur-')) {
            Logger.log(category, `Using blur background: ${virtualBackground}`)

            const blur = new GaussianBlurBackgroundProcessor({
              assetsPath: '/assets',
              maskBlurRadius: virtualBackground === 'blur-light' ? 5 : 10,
              blurFilterRadius: virtualBackground === 'blur-light' ? 5 : 10,
            })

            await blur.loadModel()
            videoTrack.addProcessor(blur)
            Logger.log(category, 'Saving video processor')
            setVideoProcessor(blur)

            const cameraMediaStream = new MediaStream([
              videoTrack.processedTrack || videoTrack.mediaStreamTrack,
              audioTrack.mediaStreamTrack,
            ])

            setCameraMediaRecorder(
              new MediaRecorder(cameraMediaStream, {
                mimeType: 'video/webm;codecs=VP9',
              })
            )
          } else {
            Logger.log(category, `Using ${virtualBackground} background`)

            const img = new Image()
            img.src = `/images/backgrounds/${virtualBackground}.jpg` // TODO: Will need to use the image url here instead

            img.onload = async () => {
              Logger.log(category, 'Image loaded')

              const virtual = new VirtualBackgroundProcessor({
                assetsPath: '/assets',
                backgroundImage: img,
                maskBlurRadius: 5,
              })
              await virtual.loadModel()

              videoTrack.addProcessor(virtual)
              setVideoProcessor(virtual)

              const cameraMediaStream = new MediaStream([
                videoTrack.processedTrack || videoTrack.mediaStreamTrack,
                audioTrack.mediaStreamTrack,
              ])

              setCameraMediaRecorder(
                new MediaRecorder(cameraMediaStream, {
                  mimeType: 'video/webm;codecs=VP9',
                })
              )
            }
          }
        } else {
          Logger.log(category, 'Using no background')

          const cameraMediaStream = new MediaStream([
            videoTrack.mediaStreamTrack,
            audioTrack.mediaStreamTrack,
          ])

          setCameraMediaRecorder(
            new MediaRecorder(cameraMediaStream, {
              mimeType: 'video/webm;codecs=VP9',
            })
          )
        }
      }
    }

    setupRecorders()
  }, [publications, virtualBackground, participant])

  useEffect(() => {
    // Setup screen recorder
    if (screenShareStream && !screenMediaRecorder && !participant.isGuest) {
       Logger.log(category, 'Setting the screen media recorder')
      setScreenMediaRecorder(
        new MediaRecorder(screenShareStream, {
          mimeType: 'video/webm;codecs=VP9',
        })
      )
    } else {
      Logger.log(category, 'Resetting the screen media recorder')
      setScreenMediaRecorder(undefined)
    }
  }, [screenShareStream, participant])

  useEffect(() => {
    if (cameraMediaRecorder) {
      if (isRecording && cameraMediaRecorder.state === 'inactive') {
        Logger.log(category, 'Starting camera recording')
        setCameraChunks([])
        cameraMediaRecorder.start(RECORDING_TIME_SLICE)
      } else if (!isRecording && cameraMediaRecorder.state === 'recording') {
        Logger.log(category, 'Stopping camera recording')
        cameraMediaRecorder.stop()
      }
    } else {
      Logger.log(category, 'No camera media recorder to perform actions')
    }

    if (screenMediaRecorder) {
      if (isRecording && screenMediaRecorder.state === 'inactive') {
        Logger.log(category, 'Starting screen recording')
        setScreenChunks([])
        screenMediaRecorder.start()
      } else if (!isRecording && screenMediaRecorder.state === 'recording') {
        Logger.log(category, 'Stopping screen recording')
        screenMediaRecorder.stop()
      }
    } else {
      Logger.log(category, 'No screen media recorder to perform actions')
    }
  }, [isRecording, cameraMediaRecorder, screenMediaRecorder])

  if (cameraMediaRecorder) {
    cameraMediaRecorder.ondataavailable = (recordingEvent: BlobEvent) => {
      Logger.log(category, `Got camera chunk event: ${new Date().getTime()}`)
      setHadBlob(true)
      setCameraChunks([...cameraChunks, recordingEvent.data])
    }

    cameraMediaRecorder.onstop = () => {
      Logger.log(category, `Creating camera blob from ${cameraChunks.length} chunks`)
      setCameraBlob(new Blob(cameraChunks, { type: 'video/webm' }))
    }
  }

  if (screenMediaRecorder) {
    screenMediaRecorder.ondataavailable = (recordingEvent: BlobEvent) => {
      Logger.log(category, `Got screen chunk event: ${new Date().getTime()}`)
      setScreenChunks([...screenChunks, recordingEvent.data])
    }
    screenMediaRecorder.onstop = () => {
      Logger.log(category, `Creating screen blob from ${cameraChunks.length} chunks`)
      setScreenBlob(new Blob(screenChunks, { type: 'video/webm' }))
    }
  }

  // Only the host can update the recording
  const clearBlobs = useCallback(() => {
    setCameraChunks([])
    setScreenChunks([])
    setCameraBlob(undefined)
    setScreenBlob(undefined)
  }, [])

  return {
    cameraChunks,
    screenChunks,
    cameraBlob,
    screenBlob,
    haveRecordings: Boolean(cameraBlob) || Boolean(screenBlob),
    clearBlobs,
    hadBlob,
  }
}
