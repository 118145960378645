import { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { Snackbar } from '@material-ui/core'
import { FileRejection, useDropzone } from 'react-dropzone'
import { makeStyles, Theme } from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { formatBytes } from 'src/utils/bytes'
import {
  UPLOAD_FILE_LIMIT,
  UPLOAD_FILE_MAX_SIZE_MB,
} from 'src/constants/global'

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export interface FileUploaderProps {
  initialFiles: File[]
  filesSelected: (files: File[]) => void
  required?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  uploadBox: {
    border: '1px dashed #aaa',
    borderRadius: '4px',
    padding: '24px',
    textAlign: 'center',
  },
  fileList: {
    margin: 0,
    padding: 0,
    paddingTop: theme.spacing(1),
    listStyleType: 'none',
    textAlign: 'left',
  },
  fileListDelete: {
    padding: '0px',
    paddingTop: theme.spacing(1),
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

export function FileUploader({
  initialFiles,
  filesSelected,
  required,
}: FileUploaderProps) {
  const snackbarHide = 5000
  const maxSize = UPLOAD_FILE_MAX_SIZE_MB * 1024 * 1024
  const classes = useStyles()

  // File added snackbar
  const [
    showingSuccessfulFileUploadSnackbar,
    setShowingSuccessfulFileUploadSnackbar,
  ] = useState(false)
  const [showingErrorFileUploadSnackbar, setShowingErrorFileUploadSnackbar] =
    useState(false)
  const [fileAddedMessage, setFileAddedMessage] = useState('')
  const [fileRejectedMessage, setFileRejectedMessage] = useState('')
  const [files, setFiles] = useState<File[]>([])

  const handleSnackBarClose = () => {
    setShowingSuccessfulFileUploadSnackbar(false)
    setShowingErrorFileUploadSnackbar(false)
  }

  // Drop zone methods
  const { getRootProps, getInputProps } = useDropzone({
    maxSize,
    onDropAccepted: (filesAdded) => onFilesAdded(filesAdded),
    onDropRejected: (filesRejected) => onFilesRejected(filesRejected),
  })

  const onFilesAdded = (filesAdded: File[]) => {
    // Check if the new files aren't more than allowed
    const newFiles = filesAdded.length + files.length
    if (newFiles > UPLOAD_FILE_LIMIT) {
      setFileRejectedMessage(
        `Maximum of ${UPLOAD_FILE_LIMIT} files allowed, remove some of the files`
      )
      setShowingErrorFileUploadSnackbar(true)
      return
    }

    setFiles([...files, ...filesAdded])
    setFileAddedMessage(
      `Added ${filesAdded.length} file${
        filesAdded.length !== 1 ? 's' : ''
      } successfully`
    )
    setShowingSuccessfulFileUploadSnackbar(true)
  }

  const onFilesRejected = (filesRejected: FileRejection[]) => {
    if (filesRejected.length > UPLOAD_FILE_LIMIT) {
      setFileRejectedMessage(`Maximum of ${UPLOAD_FILE_LIMIT} files allowed`)
    } else {
      for (const file of filesRejected) {
        if (file.file.size > maxSize) {
          setFileRejectedMessage(`Maximum file size of 1GBs`)
          break
        }
      }
    }
    setShowingErrorFileUploadSnackbar(true)
  }

  const deleteFile = (index: number) => {
    const newFiles = []
    for (let ii = 0; ii < files.length; ii++) {
      if (ii !== index) {
        newFiles.push(files[ii])
      }
    }
    setFiles(newFiles)
  }

  // Set initial files
  useEffect(() => {
    setFiles(initialFiles)
  }, [])

  // Keep the parent files up to date
  useEffect(() => {
    filesSelected(files)
  }, [files])

  // Show files added to user
  const fileItems = files.map((file: File, index: number) => (
    <li key={`selected-file-${index}`}>
      <Typography variant="caption">
        {`${index + 1}. ${file.name} (${formatBytes(file.size)}) `}
      </Typography>
      <Typography
        className={classes.fileListDelete}
        color="error"
        variant="caption"
        onClick={() => deleteFile(index)}
      >
        Delete
      </Typography>
    </li>
  ))

  return (
    <div>
      <div {...getRootProps({ className: classes.uploadBox })}>
        <input {...getInputProps()} />
        <Typography variant="body1">
          Drag and drop your presentation files here, or click to select files
          {required && ' (required)'}
        </Typography>
        <Typography variant="caption">
          ({UPLOAD_FILE_LIMIT} files is the maximum number of files you can
          upload with a limit of 1GB per file, if you need more get in contact
          with us at hello@cloudpresent.co)
        </Typography>
      </div>

      {files.length > 0 && (
        <div>
          <ul className={classes.fileList}>{fileItems}</ul>
        </div>
      )}

      <Snackbar
        key="success-snackbar-bottom-left"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={showingSuccessfulFileUploadSnackbar}
        onClose={handleSnackBarClose}
        autoHideDuration={snackbarHide}
      >
        <Alert onClose={handleSnackBarClose} severity="success">
          {fileAddedMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        key="error-snackbar-bottom-left"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={showingErrorFileUploadSnackbar}
        onClose={handleSnackBarClose}
        autoHideDuration={snackbarHide}
      >
        <Alert onClose={handleSnackBarClose} severity="error">
          {fileRejectedMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}
