import { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Lottie from 'lottie-react'
import useUploadingContext from 'src/hooks/useUploadingContext/useUploadingContext'

import { FileUploader } from '../FileUploader/FileUploader'
import ConfirmUploadDialogProgress from './ConfirmUploadDialogProgress/ConfirmUploadDialogProgress'
import ConfirmUploadDialogError from './ConfirmUploadDialogError/ConfirmUploadDialogError'
import { useAppState } from 'src/state'
import PreviewVideoButton from '../Buttons/PreviewVideoButton/PreviewVideoButton'

// @ts-ignore
import uploadAnimation from 'src/assets/lottie-upload.json'
// @ts-ignore
import completeAnimation from 'src/assets/lottie-complete.json'
// @ts-ignore
import deleteAnimation from 'src/assets/lottie-delete.json'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  container: {
    width: '500px',
  },
  button: {
    float: 'right',
  },
  dropZone: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttons: {
    margin: theme.spacing(2),
  },
}))

export default function ConfirmUploadDialog() {
  const classes = useStyles()
  const { groupRecording } = useAppState()
  const {
    uploadDialog,
    toggleUploading,
    toggleUploadDialog,
    closeUploadDialog,
    canUpload,
    isUploading,
    uploadComplete,
    uploadDeleted,
    uploadError,
    setAssetsBlob,
    info,
    setInfo,
    clearBlobs,
    deleteUpload,
    resetRecording,
    resetUpload,
    resetAssets,
    resetHostFields,
    resetProgress,
    isUsingScreenShare,
  } = useUploadingContext()
  const [files, setFiles] = useState<File[]>([])
  const [assetsError, setAssetsError] = useState('')

  const isDisabled = isUploading || !canUpload
  const isRequired = !groupRecording && isUsingScreenShare && files.length === 0

  const filesUpdated = (newFiles: File[]) => {
    setAssetsError('')
    setFiles(newFiles)
  }

  const onDelete = () => {
    deleteUpload()
  }

  const onUpload = () => {
    if (isRequired) {
      setAssetsError(
        'Because you shared your screen we need at least one asset'
      )
      return
    }

    if (files.length > 0) {
      setAssetsBlob(files)
    }
    toggleUploading(true)
  }

  const onClose = () => {
    closeUploadDialog()
    resetRecording()
    resetUpload()
    resetAssets()
    clearBlobs()
    resetHostFields()
    resetProgress()
  }

  const uploading = isUploading

  // TODO: Hide participant upload if cancelled?
  // https://robertmarshall.dev/blog/disablebackdropclick-of-forwardrefdialog-is-deprecated-solution-and-example/
  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={uploadDialog}
      onClose={toggleUploadDialog}
      className={classes.root}
    >
      <DialogTitle>
        {uploading && !uploadComplete && 'Uploading'}
        {uploading && uploadComplete && 'Upload Complete'}
        {!uploading && 'Confirm Upload'}
      </DialogTitle>
      <DialogContent className={classes.container}>
        {!uploading && !uploadComplete && (
          <Grid container>
            <Grid item sm={12}>
              {groupRecording ? (
                <Typography variant="body1">
                  Please provide any additional information for our editors.
                  When you click finish, please make sure all participants
                  videos have been uploaded. You can then end the session when
                  you are done.
                </Typography>
              ) : (
                <Typography variant="body1">
                  Please provide any additional information for our editors, you
                  can then end the session when you are done.
                </Typography>
              )}
            </Grid>

            <Grid item sm={12} className={classes.dropZone}>
              <FileUploader
                initialFiles={files}
                filesSelected={filesUpdated}
                required={isRequired}
              />

              {assetsError.length > 0 && (
                <Typography color="error" variant="body2">
                  {assetsError}
                </Typography>
              )}
            </Grid>

            <Grid item sm={12}>
              <TextField
                variant="outlined"
                id="additionalNotes"
                label="Additional Notes"
                type="text"
                multiline
                rows={4}
                fullWidth
                value={info}
                onChange={(e) => setInfo(e.target.value)}
              />
            </Grid>

            {isDisabled && (
              <Grid item sm={12}>
                <Typography color="secondary" variant="body1">
                  There are no recordings present to upload. Please contact
                  support if this keeps appearing.
                </Typography>
              </Grid>
            )}
          </Grid>
        )}

        {uploading && !uploadComplete && (
          <Grid container justifyContent="center">
            <Grid item xs={3}>
              <Lottie animationData={uploadAnimation} loop autoPlay />
            </Grid>

            <Grid item xs={12}>
              <ConfirmUploadDialogProgress />
            </Grid>
          </Grid>
        )}

        {uploadComplete && !uploadDeleted && (
          <Grid container justifyContent="center">
            <Grid item xs={3}>
              <Lottie animationData={completeAnimation} loop={false} autoPlay />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Your recording was successfully uploaded to Cloud Present. If
                you would like to upload another recording, click close and
                repeat the process, otherwise end the session when you are
                ready.
              </Typography>
            </Grid>
          </Grid>
        )}

        {uploadComplete && uploadDeleted && (
          <Grid container justifyContent="center">
            <Grid item xs={3}>
              <Lottie animationData={deleteAnimation} loop={false} autoPlay />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Your recording was successfully deleted and will not be used by
                the editors.
              </Typography>
            </Grid>
          </Grid>
        )}

        {!uploading && uploadError && (
          <Grid container>
            <Grid item xs={12}>
              <ConfirmUploadDialogError error={uploadError} />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions className={classes.buttons}>
        {!uploading && !uploadComplete && !groupRecording && (
          <PreviewVideoButton />
        )}

        {!uploading && !uploadComplete && (
          <>
            <Button
              className={classes.button}
              color="secondary"
              variant="contained"
              onClick={onDelete}
              disabled={isDisabled}
            >
              Delete
            </Button>

            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={onUpload}
              disabled={isDisabled}
            >
              Finish
            </Button>
          </>
        )}

        {uploadComplete && (
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={onClose}
          >
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
