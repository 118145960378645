import { useState } from 'react'

// TODO: Should change this to video effects as blur should be switched off if a background is selected
export default function useBackgroundBlur() {
  const [virtualBackground, setVirtualBackground] = useState<string>() // Should store an object with the name, id and url for the background

  return {
    virtualBackground,
    setVirtualBackground,
  }
}
