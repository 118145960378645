import { useState, useEffect } from 'react'
import { COUNT_DOWN_DURATION } from 'src/constants/global'

export default function useTimer(isCountingDown: boolean) {
  const [countDown, setCountDown] = useState(5)
  const [showCountDown, setShowCountDown] = useState(false)

  // Run the count down timer
  useEffect(() => {
    let intervalId: NodeJS.Timer

    setShowCountDown(isCountingDown)

    if (isCountingDown) {
      setCountDown(COUNT_DOWN_DURATION / 1000)

      intervalId = setInterval(() => {
        setCountDown((prev) => Math.max((prev -= 1), 0))
      }, 1000)
    }

    return () => clearInterval(intervalId)
  }, [isCountingDown])

  return { countDown, showCountDown }
}
