import {
  FormControl,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'

export default function BackgroundSelection() {
  const { virtualBackground, setVirtualBackground } = useVideoContext()

  const virtualBackgrounds = [
    { id: 'none', label: 'None', background: 'none' },
    { id: 'blur-light', label: 'Light Blur', background: 'blur-light' },
    { id: 'blur-strong', label: 'Strong Blur', background: 'blur-strong' },
    // { id: 'plant', label: 'Plant', background: 'plant' },
    // { id: 'office', label: 'Office', background: 'office' },
    // { id: 'clock', label: 'Clock', background: 'clock' },
  ]

  function backgroundSelected(backgroundId: string) {
    setVirtualBackground(backgroundId)
  }

  return (
    <div>
      <FormControl fullWidth>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={virtualBackground || 'none'}
          onChange={(e) => backgroundSelected(e.target.value as string)}
        >
          {virtualBackgrounds.map((background) => (
            <FormControlLabel
              value={background.background}
              key={background.id}
              control={<Radio />}
              label={background.label}
            />
          ))}
        </RadioGroup>

        <FormHelperText>
          Select the blur you would like to apply
        </FormHelperText>
      </FormControl>
    </div>
  )
}
