import { createContext, ReactNode } from 'react'
import {
  IStreamingUploadProgress,
  IUploadTask,
  UploadErrorCallback,
} from 'src/constants/types'
import useSetHostValues from './useSetHostValues/useSetHostValues'
import useAssetBlob from './useAssetBlob/useAssetBlob'
import useRecording from './useRecording/useRecording'
import useUpload from './useUpload/useUpload'
import useUploadDialog from './useUploadDialog/useUploadDialog'
import useUploading from './useUploading/useUploading'
import { useAppState } from 'src/state'
import useHostUploading from './useHostUploading/useHostUploading'
import useParticipants from './useParticipants/useParticipants'
import useStreamUpload from './useStreamUpload/useStreamUpload'
import useMediaRecorder from './useMediaRecorder/useMediaRecorder'
import useScreenShareTimeCodes from './useScreenShareTimeCodes/useScreenShareTimeCodes'
import usePreviewDialog from './usePreviewDialog/usePreviewDialog'
import useTimer from './useTimer/useTimer'
import useCountDownTimer from './useCountDownTimer/useCountDownTimer'

/*
 *  The hooks used by the UploadingProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'UploadingProvider/' directory are intended to be used by the UploadingProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface IUploadingContext {
  participants: Array<{ id: string; name: string }>
  isRecording: boolean
  toggleRecording: () => void
  uploadDialog: boolean
  isHostUploading: boolean
  hasHostUploaded: boolean
  isUsingScreenShare: boolean
  toggleUploadDialog: () => void
  closeUploadDialog: () => void
  toggleHostUploadingDialog: () => void
  resetUploadError: () => void
  canUpload: boolean
  isUploading: boolean
  startedUpload: boolean
  uploadComplete: boolean
  uploadDeleted: boolean
  uploadProgress: IUploadTask
  uploadError?: string
  toggleUploading: (value: boolean) => void
  onError: (error: Error) => void
  assetsBlob?: File[]
  setAssetsBlob: (asset: File[]) => void
  info: string
  setInfo: (info: string) => void
  deleteUpload: () => void
  resetRecording: () => void
  resetUpload: () => void
  resetAssets: () => void
  resetHostFields: () => void
  resetProgress: () => void
  resetTimerValue: () => void
  recordingNumber: number
  numberChunks: number
  numberUploadedChunks: number
  streamingUploadProgress: IStreamingUploadProgress
  previewDialog: boolean
  togglePreviewDialog: () => void
  closePreviewDialog: () => void
  cameraBlob: Blob | undefined
  screenBlob: Blob | undefined
  haveRecordings: boolean
  clearBlobs: () => void
  timer: string
  counter: number
  countDown: number
  showCountDown: boolean
  cancelCountDownTimer: () => void
  hadBlob: boolean
}

export const UploadingContext = createContext<IUploadingContext>(null!)

interface UploadingProviderProps {
  children: ReactNode
  isGroup: boolean
  onError: UploadErrorCallback
}

export function UploadingProvider({
  children,
  isGroup,
  onError = () => {},
}: UploadingProviderProps) {
  const onErrorCallback = (error: Error) => {
    console.error(`ERROR: ${error}`)
    onError(error)
  }

  const { resetHostFields } = useSetHostValues(isGroup)
  const { uploadId } = useAppState()

  const {
    isCountingDown,
    isRecording,
    canUpload,
    isUsingScreenShare,
    toggleRecording,
    resetRecording,
    recordingNumber,
    resetTimer,
    resetTimerValue,
    cancelCountDownTimer,
  } = useRecording(isGroup)
  const {
    cameraChunks,
    screenChunks,
    cameraBlob,
    screenBlob,
    haveRecordings,
    clearBlobs,
    hadBlob,
  } = useMediaRecorder(isRecording)
  const { startedUpload, isUploading, toggleUploading } = useUploading()
  const { uploadDialog, toggleUploadDialog, closeUploadDialog } =
    useUploadDialog(isGroup, resetTimerValue)
  const { isHostUploading, hasHostUploaded, toggleHostUploadingDialog } =
    useHostUploading(isGroup)

  const [assetsBlob, setAssetsBlob, info, setInfo, resetAssets] = useAssetBlob()
  const {
    uploadProgress,
    uploadComplete,
    uploadDeleted,
    uploadError,
    deleteUpload,
    resetUploadError,
    resetUpload,
  } = useUpload(
    isGroup,
    isUsingScreenShare,
    isUploading,
    toggleUploading,
    info,
    assetsBlob,
    recordingNumber,
    uploadId
  )

  const { participants } = useParticipants(isGroup)
  const {
    numberChunks,
    numberUploadedChunks,
    streamingUploadProgress,
    resetProgress,
  } = useStreamUpload({
    cameraChunks,
    screenChunks,
    isRecording,
    recordingNumber,
  })

  const { previewDialog, togglePreviewDialog, closePreviewDialog } =
    usePreviewDialog()
  const { timer, counter } = useTimer(isRecording, resetTimer)
  const { countDown, showCountDown } = useCountDownTimer(isCountingDown)

  // Set time codes on screen share, muted and blurred
  useScreenShareTimeCodes(recordingNumber)

  return (
    <UploadingContext.Provider
      value={{
        participants,
        isRecording,
        toggleRecording,
        canUpload,
        startedUpload,
        isUploading,
        uploadComplete,
        uploadDeleted,
        uploadProgress,
        uploadError,
        toggleUploading,
        deleteUpload,
        uploadDialog,
        toggleUploadDialog,
        closeUploadDialog,
        resetUploadError,
        isUsingScreenShare,
        isHostUploading,
        hasHostUploaded,
        toggleHostUploadingDialog,
        assetsBlob,
        setAssetsBlob,
        info,
        setInfo,
        onError: onErrorCallback,
        resetRecording,
        resetUpload,
        resetAssets,
        resetHostFields,
        resetProgress,
        resetTimerValue,
        recordingNumber,
        numberChunks,
        numberUploadedChunks,
        streamingUploadProgress,
        previewDialog,
        togglePreviewDialog,
        closePreviewDialog,
        cameraBlob,
        screenBlob,
        haveRecordings,
        clearBlobs,
        timer,
        counter,
        countDown,
        showCountDown,
        cancelCountDownTimer,
        hadBlob,
      }}
    >
      {children}
    </UploadingContext.Provider>
  )
}
