// Background image show when recording
export const BACKGROUND = '/images/background.svg'

// The max number of participants for group recordings
export const MAX_GROUP_PARTICIPANTS = 10

// Time slice in ms for uploading to S3
export const RECORDING_TIME_SLICE = 15000

// Duration the prompt about local recordings getting uploaded
export const QUALITY_PROMPT_DURATION = 20000

// Duration the prompt about local recordings getting uploaded
export const COUNT_DOWN_DURATION = 5000

// Max upload file size
export const UPLOAD_FILE_LIMIT = 10
export const UPLOAD_FILE_MAX_SIZE_MB = 1000

export const CAMERA_RECORDING_OPTIONS = {
  audio: true,
  video: {
    frameRate: 30,
    height: 1080,
    width: 1920,
  },
}

export const SCREEN_RECORDING_OPTIONS = {
  audio: false,
  video: {
    frameRate: 24,
    // width: {
    //   ideal: 4096
    // },
    // height: {
    //   ideal: 2160
    // },
    height: 1080,
    width: 1920,
  },
  cursor: 'motion',
}

// The limit for zipping -  10 minutes in milliseconds
export const ZIPPING_TIME = 10 * 60 * 1000

// Screenshare tips key
export const SCREEN_SHARE_TIPS = 'cloud-present-screen-share-tips'
