import { useState, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen'
import UserEmailScreen from './UserEmailScreen/UserEmailScreen'
import { useAppState } from '../../state'
import useVideoContext from '../../hooks/useVideoContext/useVideoContext'
import { Logger, Participant } from '@michaelprichardson/cloudpresent-core'
import HelpButton from '../Buttons/HelpButton/HelpButton'
import md5 from 'md5'
import { validateEmail } from 'src/utils/validation'
import { isEmpty } from 'src/utils/text'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
    },
    logo: {
      position: 'absolute',
      left: 0,
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      height: '50px',
      width: '50px',
    },
    infoButtons: {
      display: 'flex',
      position: 'absolute',
      right: 0,
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    gutterBottom: {
      marginBottom: theme.spacing(1),
    },
    inputPadding: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
)

export enum PreGroupRecordSteps {
  explanationStep,
  userEmailStep,
  deviceSelectionStep,
}

export default function GroupRecordingSetupScreens({
  isGroup,
}: {
  isGroup: boolean
}) {
  const category = 'GroupRecordingSetupScreens'
  const classes = useStyles()
  const { isFetching, invalidEvent, event, groupRecording } = useAppState()
  const { getAudioAndVideoTracks } = useVideoContext()
  const [participant, setParticipant] = useState<Participant>()
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [isGuest, setGuest] = useState(false)
  const [mediaError, setMediaError] = useState<Error>()

  useEffect(() => {
    getAudioAndVideoTracks().catch((error) => {
      Logger.error(category, 'Error acquiring local media:', error)
      setMediaError(error)
    })
  }, [getAudioAndVideoTracks, mediaError])

  useEffect(() => {
    if (!isEmpty(name) && validateEmail(email)) {
      setParticipant({ id: md5(email), name, email, isGuest })
    } else {
      setParticipant(undefined)
    }
  }, [name, email, isGuest])

  return (
    <div>
      {!isFetching && (
        <>
          <a href="https://cloudpresent.co" target="_blank" rel="noreferrer">
            <div className={classes.logo}>
              <img src="/images/logo.svg" alt="Cloud Present" />
            </div>
          </a>

          <div className={classes.infoButtons}>
            <HelpButton />
          </div>
        </>
      )}

      {!isFetching && !invalidEvent && event && (
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh', width: '100%' }}
        >
          <Grid item xs={6}>
            <UserEmailScreen
              isGroup={isGroup}
              name={name}
              setName={setName}
              email={email}
              setEmail={setEmail}
              isGuest={isGuest}
              setGuest={setGuest}
              eventName={event.name}
              groupTopic={groupRecording?.topic}
            />
          </Grid>

          <Grid item xs={6}>
            <DeviceSelectionScreen
              isGroup={isGroup}
              name={name}
              participant={participant}
              isGuest={isGuest}
              eventId={event.id}
            />
          </Grid>
        </Grid>
      )}
    </div>
  )
}
