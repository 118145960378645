import { Typography } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import BackgroundSelection from 'src/components/BackgroundSelection/BackgroundSelection'
// import ToggleBackgroundBlur from '../../Buttons/ToggleBackgroundBlur/ToggleBackgroundBlur'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: theme.spacing(1),
    },
  })
)

export default function VideoEffects() {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography variant="subtitle2" gutterBottom>
        Video Effects
      </Typography>
      <BackgroundSelection />
    </div>
  )
}
