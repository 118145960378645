import { useCallback } from 'react'
import { useState } from 'react'

export default function useAssetBlob() {
  const [asset, setAsset] = useState<File[]>([])
  const [additional, setAdditional] = useState('')

  const setAssetBlob = useCallback((providedAsset: File[]) => {
    setAsset(providedAsset)
  }, [])

  // Only the host can reset the recording
  const resetAssets = useCallback(() => {
    setAsset([])
    setAdditional('')
  }, [])

  return [asset, setAssetBlob, additional, setAdditional, resetAssets] as const
}
