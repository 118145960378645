import { useRef, useEffect } from 'react'
import { IVideoTrack } from 'src/constants/types'
import { styled } from '@material-ui/core/styles'
import { Track } from 'twilio-video'

const Video = styled('video')({
  width: '100%',
  height: '100%',
})

interface VideoTrackProps {
  track: IVideoTrack
  isLocal?: boolean
  priority?: Track.Priority | null
}

export default function VideoTrack({
  track,
  isLocal,
  priority,
}: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!)

  useEffect(() => {
    const el = ref.current
    el.muted = true
    if (track.setPriority && priority) {
      track.setPriority(priority)
    }
    track.attach(el)
    return () => {
      track.detach(el)
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority('standard')
      }
    }
  }, [track, priority])

  // The local video track is mirrored
  const style = {
    transform: isLocal ? 'rotateY(180deg)' : '',
    objectFit: track.name.includes('screen')
      ? ('contain' as const)
      : ('cover' as const),
  }

  return <Video ref={ref} style={style} />
}
