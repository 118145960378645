import { useState, useCallback } from 'react'

export default function useBackgroundBlur() {
  const [blurBackground, setBlurBackground] = useState(false)

  const toggleBackgroundBlur = useCallback(() => {
    setBlurBackground(!blurBackground)
  }, [blurBackground])

  return {
    blurBackground,
    toggleBackgroundBlur,
  }
}
