import ProgressProvider from '../ProgressProvider/ProgressProvider'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import Tooltip from '@material-ui/core/Tooltip'
import { IStreamingUploadProgress } from 'src/constants/types'
import { formatBytes } from 'src/utils/bytes'

interface ICircularProgressBarProps {
  width: number
  height: number
  progress: IStreamingUploadProgress
  large?: boolean
  light?: boolean
  tooltip?: boolean
  allowUpload?: boolean
}

export default function CircularProgressBar({
  width,
  height,
  progress,
  large = false,
  light = false,
  tooltip = true,
  allowUpload = true,
}: ICircularProgressBarProps) {
  const color = light ? '#FFFFFF' : '#2D3958'
  const style = large
    ? { color, fontSize: '48px' }
    : { color, fontSize: '20px' }
  let tooltipMessage = tooltip
    ? `Uploading ${formatBytes(progress.uploaded, 1)}/${formatBytes(
        progress.total,
        1
      )}`
    : ''

  if (progress.total === 0) {
    tooltipMessage =
      'Recording status will appear here as soon as the recording starts'
  }

  if (!allowUpload) {
    tooltipMessage =
      'As you are a guest, we will no upload anything'
  }

  return (
    <div style={{ width, height }}>
      <Tooltip title={tooltipMessage}>
        <div>
          <ProgressProvider valueStart={0} valueEnd={progress.progress}>
            {(value: number) => (
              <CircularProgressbarWithChildren
                value={value}
                styles={buildStyles({
                  textSize: '16px',
                  pathTransitionDuration: 0.25,
                  pathColor: color,
                  textColor: color,
                  trailColor: 'transparent',
                  backgroundColor: '#FFFFFF',
                })}
              >
                <CloudUploadIcon style={style} />
              </CircularProgressbarWithChildren>
            )}
          </ProgressProvider>
        </div>
      </Tooltip>
    </div>
  )
}
