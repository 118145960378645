import React, { useEffect, useState } from 'react'
import Video from 'twilio-video'
import { useParams } from 'react-router-dom'
import {
  isBrowser,
  isChrome,
  // isFirefox,
  // isEdge,
  // isChromium,
} from 'react-device-detect'
import { Container, Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useAppState } from 'src/state'
import { getLocationFromIp, createAnonymousUser } from '@michaelprichardson/cloudpresent-core'

const useStyles = makeStyles({
  container: {
    marginTop: '2.5em',
  },
  paper: {
    padding: '1em',
  },
  heading: {
    marginBottom: '0.4em',
    color: 'white',
    textAlign: 'center',
  },
  body: {
    marginBottom: '0.4em',
    color: 'white',
    textAlign: 'center',
  },
})

const ErrorContainer = ({
  heading,
  body,
}: {
  heading: string
  body: string
}) => {
  const classes = useStyles()

  return (
    <Container>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12} md={6}>
          <Grid container justifyContent="center">
            <Grid item xs={12} className={classes.heading}>
              <Typography variant="h4">{heading}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1" className={classes.body}>
                {body}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default function UnsupportedBrowserWarning({
  children,
  isGroup,
}: {
  children: React.ReactElement
  isGroup: boolean
}) {
  const { getEvent, getGroup } = useAppState()
  const { company, event, topic } = useParams<{
    company: string
    event: string
    topic: string
  }>()
  const [validEvent, setEvent] = useState(true)
  const [validGroup, setGroup] = useState(true)
  const [expiredEvent, setExpiredEvent] = useState(false)
  const [locationValid, setLocationValid] = useState(true)
  const [currentLocation, setCurrentLocation] = useState('')

  const isDesktop = isBrowser
  // const isBrowserSupported = isChrome || isFirefox || isEdge || isChromium
  const isBrowserSupported = isChrome

  useEffect(() => {
    async function getData() {
      // Sign in anonymously
      await createAnonymousUser()

      // Check if the event is valid
      const eventDoc = await getEvent(company, event)

      // Check if the event has expired
      if (eventDoc.expires.toMillis() < new Date().getMilliseconds()) {
        setExpiredEvent(true)
      }

      // If event is valid and it is a group
      // TODO: Check if exists first
      if (isGroup && eventDoc) {
        const groupDoc = await getGroup(company, event, topic)
        if (!groupDoc) {
          setGroup(false)
        }

        // TODO: Check if there are space available for the new participant
      } else if (!event) {
        setEvent(false)
      }

      // Check if the location is supported
      const { valid, countryName } = await getLocationFromIp()
      setLocationValid(valid)
      setCurrentLocation(countryName)
    }

    getData()
  }, [])

  // Desktop/Laptop browser support
  if (!isDesktop) {
    return (
      <ErrorContainer
        heading={'Device not supported'}
        body={'Cloud Present currently only supports desktop browsers.'}
      />
    )
  }

  // Browser and Twilio video support
  // TODO: Fix Firefox and Edge ('Cloud Present currently only supported the following browsers: Google Chrome, Mozilla FireFox and Microsoft Edge.')
  if (!isBrowserSupported || !Video.isSupported) {
    return (
      <ErrorContainer
        heading={'Browser not supported'}
        body={
          'Cloud Present currently only supported the following browsers: Google Chrome.'
        }
      />
    )
  }

  // Valid event and group
  if (!validEvent || !validGroup) {
    return (
      <ErrorContainer
        heading={'Event does not exist'}
        body={
          'The recording URL is not valid, please make sure you have the correct link.'
        }
      />
    )
  }

  // Expired event
  if (expiredEvent) {
    return (
      <ErrorContainer
        heading={'Event expired'}
        body={'This event has expired, please contact your event organizer.'}
      />
    )
  }

  // Location support
  if (!locationValid) {
    return (
      <ErrorContainer
        heading={'Region not supported'}
        body={
          currentLocation.length > 0
            ? `Cloud Present currently does not support ${currentLocation}.`
            : "We are unable to determine your region, please ensure you are connected to the internet and that your firewall isn't blocking your location"
        }
      />
    )
  }

  return children
}
