import { attachUploadingStatusListener, detachUploadingStatusListener, removeGroupSession, setUploaded, setUploading } from '@michaelprichardson/cloudpresent-core'
import { useState, useEffect, useCallback } from 'react'
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'
import { useAppState } from 'src/state'

export default function useRecording(isGroup: boolean) {
  const { room } = useVideoContext()
  const { participant, groupRecording, leaveSession } = useAppState()
  const [isParticipant, setParticipant] = useState(false)
  const [isHostUploading, setHostUploading] = useState(false)
  const [hasHostUploaded, setHasHostUploaded] = useState(false)
  const [hasHostLeft, setHasHostLeft] = useState(false)
  const [haveParticipant, setHaveParticipant] = useState(false)

  useEffect(() => {
    // Only when the participant is set the figure out of they are the host
    if (!haveParticipant && participant && isGroup) {
      setParticipant(participant.id !== groupRecording.host.id)
      setHaveParticipant(true)
    } else {
      setParticipant(false)
      setHaveParticipant(false)
    }
  }, [participant, isGroup])

  useEffect(() => {
    const setupRecording = async () => {
      // If there is a participant and we know if they are the host or not then either listen for updates or set the default
      if (haveParticipant && isParticipant) {
        attachUploadingStatusListener(groupRecording.eventId, groupRecording.id, (data: any) => {
          if (data) {
            // Check if host is uploading
            const uploading = data.uploading || false
            setHostUploading(uploading)

            // Check if host has closed dialog
            const uploaded = data.uploaded || false
            setHasHostUploaded(uploaded)

            // Check if host has uploaded
            const hostLeft = data.hostLeft || false
            setHasHostLeft(hostLeft)
          } else {
            setHostUploading(false)
          }
        })
      }
    }

    setupRecording()

    return () => {
      // Detach the listener if there is one
      if (haveParticipant && isParticipant) {
        detachUploadingStatusListener(groupRecording.eventId, groupRecording.id)
      }
    }
  }, [
    isParticipant,
    haveParticipant,
    groupRecording,
    isHostUploading,
  ])

  // Disconnect the participant from the room
  useEffect(() => {
    if (hasHostLeft && room.state === 'connected') {
      leaveSession()
      room.disconnect()
    }
  }, [hasHostLeft, room])

  // Only the host can update the uploading field
  const toggleHostUploadingDialog = useCallback(() => {
    const updateRecording = async () => {
      // Update database with uploading
      await setUploading(groupRecording.eventId, groupRecording.id, !isHostUploading)
      await setHostUploading(!isHostUploading);

      // Set uploaded to true
      if (!isHostUploading) {
        await setUploaded(groupRecording.eventId, groupRecording.id, true)
      }
    }

    if (!isParticipant) {
      updateRecording()
    }
  }, [groupRecording, isParticipant, isHostUploading])

  // Only the host can reset the recording
  const resetDatabase = useCallback(() => {
    const reset = async () => {
      // Update database with uploading
      await removeGroupSession(groupRecording.eventId, groupRecording.id)
    }

    reset()
  }, [])

  return {
    isHostUploading,
    hasHostUploaded,
    toggleHostUploadingDialog,
    resetDatabase,
  }
}
