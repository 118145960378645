import { Grid, Typography } from '@material-ui/core'
import useUploadingContext from 'src/hooks/useUploadingContext/useUploadingContext'
import { formatBytes } from 'src/utils/bytes'
import CircularProgressBar from '../../CircularProgressBar/CircularProgressBar'

export default function BusyUploading({ isHost }: { isHost: boolean }) {
  const { streamingUploadProgress } = useUploadingContext()

  const sizeFormat = `${formatBytes(
    streamingUploadProgress.uploaded,
    1
  )}/${formatBytes(streamingUploadProgress.total, 1)}`

  return (
    <Grid container justifyContent="center">
      <Grid item sm={3}>
        <CircularProgressBar
          width={100}
          height={100}
          progress={streamingUploadProgress}
          large={true}
          light={false}
          tooltip={false}
        />
      </Grid>
      <Grid item sm={12}>
        <Typography
          variant="body1"
          style={{ marginTop: '8px', marginBottom: '8px', textAlign: 'center' }}
        >
          {`Completing your upload ${sizeFormat}`}
        </Typography>
      </Grid>
      <Grid item sm={12}>
        {isHost ? (
          <Typography variant="body1" style={{ marginTop: '8px' }}>
            Once we have finished uploading your video files, you will be able
            to select assets and add notes or skip if you have nothing to add.
            You will be able to delete the take in the next step.{' '}
            <strong>
              (Important: Please do not close the tab until the upload is
              complete)
            </strong>
          </Typography>
        ) : (
          <Typography variant="body1" style={{ marginTop: '8px' }}>
            Once we have finished uploading your video files you can close this
            dialog and continue to chat to your fellow participants.{' '}
            <strong>
              (Important: Please do not close the tab until the upload is
              complete)
            </strong>
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}
