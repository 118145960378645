import { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import SettingsIcon from '@material-ui/icons/Settings'
import DeviceSelectionDialog from 'src/components/DeviceSelectionDialog/DeviceSelectionDialog'
import useUploadingContext from 'src/hooks/useUploadingContext/useUploadingContext'

const DISABLED_SETTINGS_RECORDING =
  'You can only change your settings when you are not recording'

export default function SettingsButton() {
  const [open, setOpen] = useState(false)
  const { isRecording } = useUploadingContext()

  const isDisabled = isRecording
  const tooltipMessage = isRecording ? DISABLED_SETTINGS_RECORDING : ''

  return (
    <Tooltip
      title={tooltipMessage}
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
    >
      <span>
        <IconButton
          size="medium"
          color="primary"
          aria-label="settings"
          onClick={() => setOpen(true)}
          disabled={isDisabled}
        >
          <SettingsIcon />
        </IconButton>

        <DeviceSelectionDialog open={open} onClose={() => setOpen(false)} />
      </span>
    </Tooltip>
  )
}
