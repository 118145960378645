import { ChangeEvent, useState } from 'react'
import {
  makeStyles,
  TextField,
  // Checkbox,
  Grid,
  Theme,
  Card,
  CardHeader,
  CardContent,
  Typography,
  // FormControlLabel,
} from '@material-ui/core'
import { validateEmail } from 'src/utils/validation'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  gutterBottom: {
    marginBottom: theme.spacing(1),
  },
  inputPadding: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    float: 'right',
  },
  leftPadding: {
    marginLeft: theme.spacing(1),
  },
  recommended: {
    color: theme.palette.white.main,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}))

interface UserEmailScreenProps {
  eventName: string
  isGroup: boolean
  groupTopic?: string
  name: string
  setName: (name: string) => void
  email: string
  setEmail: (email: string) => void
  isGuest: boolean
  setGuest: (checked: boolean) => void
}

export default function UserEmailScreen({
  eventName,
  isGroup,
  groupTopic,
  name,
  setName,
  email,
  setEmail,
  isGuest,
  setGuest,
}: UserEmailScreenProps) {
  const classes = useStyles()
  const [emailError, setEmailError] = useState('')

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setName(value)
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    // Force email to lowercase and trim whitespace
    const cleanedEmail = value.toLowerCase().trim()
    setEmail(cleanedEmail)

    if (!validateEmail(cleanedEmail) && cleanedEmail.length !== 0) {
      setEmailError('The email address you entered is not valid')
    } else {
      setEmailError('')
    }
  }

  // const handleGuestSelected = (event: ChangeEvent<HTMLInputElement>) => {
  //   setGuest(event.target.checked)
  // }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh', width: '100%' }}
    >
      <Grid item xs={8}>
        <Card className={classes.root} elevation={0}>
          <CardHeader
            title={`You are about to enter a ${
              isGroup ? 'group' : ''
            } recording for ${eventName}`}
          />
          <CardContent>
            {isGroup && (
              <Typography variant="body1" className={classes.gutterBottom}>
                Topic: {groupTopic}
              </Typography>
            )}
            <Typography variant="body1" className={classes.gutterBottom}>
              {`Please enter your name and email address associated with this ${
                isGroup ? 'group' : ''
              } recording. We only use it to help identify who you are.`}
            </Typography>
            <Grid container justifyContent="center">
              <Grid item md={12}>
                <TextField
                  key="participant-name"
                  id="participant-name"
                  variant="outlined"
                  fullWidth
                  label="Name (required)"
                  value={name}
                  onChange={handleNameChange}
                />
              </Grid>

              <Grid item md={12}>
                <TextField
                  className={classes.inputPadding}
                  key="participant-email-address"
                  id="participant-email-address"
                  variant="outlined"
                  fullWidth
                  label="Email Address (required)"
                  value={email}
                  error={emailError.length > 0}
                  helperText={emailError}
                  onChange={handleEmailChange}
                />
              </Grid>

              {/* {isGroup && (
                <Grid item md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isGuest}
                        onChange={handleGuestSelected}
                        name="isGuest"
                        color="primary"
                      />
                    }
                    label="Join as Guest (You will not be recorded)"
                  />
                </Grid>
              )} */}
            </Grid>
          </CardContent>
        </Card>

        <Typography variant="body1" className={classes.recommended}>
          For the best experience, we recommend using a computer with an i5
          Intel CPU (or equivalent) and higher and at least 8GB of RAM
        </Typography>
      </Grid>
    </Grid>
  )
}
