import ParticipantInfo from '../ParticipantInfo/ParticipantInfo'
import ParticipantInfoFill from '../ParticipantInfoFill/ParticipantInfoFill'
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks'
import { Participant as IParticipant } from 'twilio-video'

interface ParticipantProps {
  participant: IParticipant
  videoOnly?: boolean
  enableScreenShare?: boolean
  onClick?: () => void
  isSelected?: boolean
  isScreenShare?: boolean
  isDominantSpeaker?: boolean
  isLocalParticipant?: boolean
  hideParticipant?: boolean
  isHostScreenShare?: boolean
  limitName?: boolean
  fill?: boolean
}

export default function Participant({
  participant,
  videoOnly,
  enableScreenShare,
  onClick,
  isSelected,
  isScreenShare,
  isLocalParticipant,
  hideParticipant,
  isHostScreenShare,
  isDominantSpeaker,
  limitName,
  fill,
}: ParticipantProps) {
  const tracks = (
    <ParticipantTracks
      participant={participant}
      videoOnly={videoOnly}
      enableScreenShare={enableScreenShare}
      isLocalParticipant={isLocalParticipant}
      isScreenShare={isScreenShare}
      isHostScreenShare={isHostScreenShare}
    />
  )

  if (fill) {
    return (
      <ParticipantInfoFill
        participant={participant}
        onClick={onClick}
        isSelected={isSelected}
        isLocalParticipant={isLocalParticipant}
        hideParticipant={hideParticipant}
        isScreenShare={isScreenShare}
        isHostScreenShare={isHostScreenShare}
        isDominantSpeaker={isDominantSpeaker}
        limitName={limitName}
      >
        {tracks}
      </ParticipantInfoFill>
    )
  } else {
    return (
      <ParticipantInfo
        participant={participant}
        onClick={onClick}
        isSelected={isSelected}
        isLocalParticipant={isLocalParticipant}
        hideParticipant={hideParticipant}
        isScreenShare={isScreenShare}
        isHostScreenShare={isHostScreenShare}
        isDominantSpeaker={isDominantSpeaker}
        limitName={limitName}
      >
        {tracks}
      </ParticipantInfo>
    )
  }
}
