import AudioLevelIndicator from '../../AudioLevelIndicator/AudioLevelIndicator'
import { LocalAudioTrack } from 'twilio-video'
import {
  FormControl,
  MenuItem,
  Typography,
  Select,
  Grid,
  FormHelperText,
} from '@material-ui/core'
import { SELECTED_AUDIO_INPUT_KEY } from 'src/constants/video'
import { useAudioInputDevices } from 'src/hooks/deviceHooks/deviceHooks'
import useMediaStreamTrack from 'src/hooks/useMediaStreamTrack/useMediaStreamTrack'
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'

export default function AudioInputList() {
  const audioInputDevices = useAudioInputDevices()
  const { localTracks, updateAudioTrackId } = useVideoContext()

  const localAudioTrack = localTracks.find(
    (track) => track.kind === 'audio'
  ) as LocalAudioTrack
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack)
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId

  function replaceTrack(newDeviceId: string) {
    updateAudioTrackId(newDeviceId)
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId)

    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } })
  }

  return (
    <div>
      <Typography variant="subtitle2" gutterBottom>
        Audio Input
      </Typography>
      <Grid container alignItems="center" justifyContent="space-between">
        <div className="inputSelect">
          {audioInputDevices.length > 1 ? (
            <FormControl fullWidth>
              <Select
                onChange={(e) => replaceTrack(e.target.value as string)}
                value={localAudioInputDeviceId || ''}
                variant="outlined"
              >
                {audioInputDevices.map((device) => (
                  <MenuItem value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Select the microphone you would like to use
              </FormHelperText>
            </FormControl>
          ) : (
            <Typography>
              {localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}
            </Typography>
          )}
        </div>
        <AudioLevelIndicator audioTrack={localAudioTrack} color="black" />
      </Grid>
    </div>
  )
}
