import { LocalVideoTrack, RemoteVideoTrack, TwilioError } from 'twilio-video'
import { EventEmitter } from 'events'

export type RoomType = 'group' | 'peer-to-peer'
export type Callback = (...args: any[]) => void
export type ErrorCallback = (error: TwilioError | Error) => void
export type UploadErrorCallback = (error: Error) => void
export type IVideoTrack = LocalVideoTrack | RemoteVideoTrack
export type IBlob = Blob | undefined

export enum RecordingState {
  NOT_STARTED = 0,
  RECORDING = 1,
  HAVE_RECORDING = 2,
}
export interface PreflightTestReport {
  isTurnRequired: boolean
  stats: {
    jitter: {
      min: number
      max: number
      average: number
    }
    rtt?: {
      min: number
      max: number
      average: number
    }
    outgoingBitrate?: {
      min: number
      max: number
      average: number
    }
    incomingBitrate?: {
      min: number
      max: number
      average: number
    }
    packetLoss: {
      min: number
      max: number
      average: number
    }
    networkQuality: {
      min: number
      max: number
      average: number
    }
  }
}

export declare interface PreflightTest extends EventEmitter {
  on(event: 'completed', listener: (report: PreflightTestReport) => void): this
  on(event: 'failed', listener: (error: Error) => void): this
  stop: () => void
}

declare module 'twilio-video' {
  // These help to create union types between Local and Remote VideoTracks
  interface LocalVideoTrack {
    isSwitchedOff: undefined
    setPriority: undefined
  }
}

export interface IUploadTask {
  pending: boolean
  processing: boolean
  uploading: boolean
  progress: number
  uploaded: number
  total: number
}

export interface IUploadTasks {
  camera: IUploadTask
  audio: IUploadTask
  screen: IUploadTask
  assets: IUploadTask
}

export interface IUploadingTask {
  processing: boolean
  cameraUploading: boolean
  cameraProgress: number
  cameraSize: number
  screenUploading: boolean
  screenProgress: number
  screenSize: number
  assetsUploading: boolean
  assetsProgress: number
  assetsSize: number
}

export interface IStreamingUploadProgress {
  started: boolean
  complete: boolean
  progress: number
  uploaded: number
  total: number
}
