import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import Typography from '@material-ui/core/Typography'
import useUploadingContext from 'src/hooks/useUploadingContext/useUploadingContext'
import { IconButton } from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import Grid from '@material-ui/core/Grid'
import { useAppState } from 'src/state'
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    white: {
      color: '#FFFFFF',
    },
    centerItems: {
      textAlign: 'center',
    },
  })
)

export default function CountDownTimer() {
  const classes = useStyles()
  const { participant, groupRecording } = useAppState()
  const { isSharingScreen } = useVideoContext()
  const { showCountDown, countDown, cancelCountDownTimer } =
    useUploadingContext()

  let isHost = true
  if (groupRecording) {
    isHost = participant.id === groupRecording.host.id
  }

  return (
    <Backdrop className={classes.backdrop} open={showCountDown}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.centerItems}>
          <Typography variant="h1" className={classes.white}>
            Starting in {countDown}...
          </Typography>
        </Grid>

        {!isSharingScreen && !groupRecording && (
          <Grid item xs={12} className={classes.centerItems}>
            <Typography variant="body1" className={classes.white}>
              You will not be able to share your screen while recording
            </Typography>
          </Grid>
        )}

        {isHost && (
          <Grid item xs={12} className={classes.centerItems}>
            <IconButton
              size="medium"
              color="default"
              aria-label="cancel"
              className={classes.white}
              onClick={(e) => cancelCountDownTimer()}
            >
              <CancelIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Backdrop>
  )
}
