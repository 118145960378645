import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_VIDEO_INPUT_KEY,
} from '../../../constants/video'
import { useCallback, useState } from 'react'
import Video, {
  LocalVideoTrack,
  LocalAudioTrack,
  CreateLocalTrackOptions,
} from 'twilio-video'
import {
  GaussianBlurBackgroundProcessor,
} from '@twilio/video-processors'
import {
  useAudioInputDevices,
  useVideoInputDevices,
} from '../../../hooks/deviceHooks/deviceHooks'

export default function useLocalTracks(
  blurBackground: boolean,
  virtualBackground?: string
) {
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>()
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>()
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false)

  const localAudioDevices = useAudioInputDevices()
  const localVideoDevices = useVideoInputDevices()

  const hasAudio = localAudioDevices.length > 0
  const hasVideo = localVideoDevices.length > 0

  const getLocalAudioTrack = useCallback((deviceId?: string) => {
    const options: CreateLocalTrackOptions = {}

    if (deviceId) {
      options.deviceId = { exact: deviceId }
    }

    return Video.createLocalAudioTrack(options).then((newTrack) => {
      setAudioTrack(newTrack)
      return newTrack
    })
  }, [])

  const getLocalVideoTrack = useCallback(
    async (newOptions?: CreateLocalTrackOptions) => {
      const options: CreateLocalTrackOptions = {
        ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
        name: `camera-${Date.now()}`,
        ...newOptions,
      }

      const videoTrack = await Video.createLocalVideoTrack(options)

      if (blurBackground) {
        const bg = new GaussianBlurBackgroundProcessor({
          assetsPath: '/assets',
          maskBlurRadius: 10,
          blurFilterRadius: 5,
        })

        await bg.loadModel()
        videoTrack.addProcessor(bg)
      }

      setVideoTrack(videoTrack)
      return videoTrack
    },
    [blurBackground]
  )

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop()
      setVideoTrack(undefined)
    }
  }, [videoTrack])

  const getAudioAndVideoTracks = useCallback(() => {
    if (!hasAudio && !hasVideo) {
      return Promise.resolve()
    }
    if (isAcquiringLocalTracks || audioTrack || videoTrack) {
      return Promise.resolve()
    }

    setIsAcquiringLocalTracks(true)

    const selectedAudioDeviceId = window.localStorage.getItem(
      SELECTED_AUDIO_INPUT_KEY
    )
    const selectedVideoDeviceId = window.localStorage.getItem(
      SELECTED_VIDEO_INPUT_KEY
    )

    const hasSelectedAudioDevice = localAudioDevices.some(
      (device) =>
        selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId
    )
    const hasSelectedVideoDevice = localVideoDevices.some(
      (device) =>
        selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId
    )

    const localTrackConstraints = {
      video: hasVideo && {
        ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
        name: `camera-${Date.now()}`,
        ...(hasSelectedVideoDevice && {
          deviceId: { exact: selectedVideoDeviceId! },
        }),
      },
      audio: hasSelectedAudioDevice
        ? { deviceId: { exact: selectedAudioDeviceId! } }
        : hasAudio,
    }

    return Video.createLocalTracks(localTrackConstraints)
      .then(async (tracks) => {
        const vt = tracks.find((track) => track.kind === 'video')
        const at = tracks.find((track) => track.kind === 'audio')
        if (vt) {
          setVideoTrack(vt as LocalVideoTrack)
        }
        if (at) {
          setAudioTrack(at as LocalAudioTrack)
        }
      })
      .finally(() => setIsAcquiringLocalTracks(false))
  }, [hasAudio, hasVideo, audioTrack, videoTrack, localAudioDevices, localVideoDevices, isAcquiringLocalTracks])

  const localTracks = [audioTrack, videoTrack].filter(
    (track) => track !== undefined
  ) as Array<LocalAudioTrack | LocalVideoTrack>

  return {
    audioTrack,
    videoTrack,
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  }
}
