export function urlSafeName(text: string): string {
  return text
    .replace(/[`~!@#$%^&*()_|+=?:'",.<>\{\}\[\]\\\/\s]/g, '-')
    .toLowerCase()
}

export function shorten(text: string, limit: number = 16): string {
  const length = text.length
  if (length > limit) {
    const edge = Math.floor(limit / 2)
    return `${text.substring(0, edge)}...${text.substring(
      length - edge,
      length
    )}`
  }
  return text
}

export function isEmpty(text: string): boolean {
  return text.length === 0
}

export function zeroPadding(value: number, padding: number = 3): string {
  return ('000' + value.toString()).substr(-padding)
}
