import { QUALITY_PROMPT_DURATION } from 'src/constants/global'
import Snackbar from '../Snackbar/Snackbar'

export default function RecordingQualityNotification({
  open,
  onClose,
}: {
  open: boolean
  onClose: (value: boolean) => void
}) {
  return (
    <Snackbar
      variant="success"
      headline=""
      message="The recordings we receive look and sound much better than what you see live."
      open={open}
      handleClose={() => onClose(false)}
      key={'what-we-use-snackbar'}
      autoHideDuration={QUALITY_PROMPT_DURATION}
    />
  )
}
