import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'
import Participant from '../Participant/Participant'
import { useAppState } from 'src/state'
import WaitingForHostParticipant from '../WaitingForHostParticipant/WaitingForHostParticipant'
import useScreenShareParticipant from 'src/hooks/useScreenShareParticipant/useScreenShareParticipant'

export default function HostScreenShare() {
  const { isSharingScreen } = useVideoContext()
  const { participant, groupRecording } = useAppState()
  const { screenShareParticipant } = useScreenShareParticipant()

  // If the current user is the host then use the local stream
  // otherwise check if the remote stream contains the host email
  let isHostParticipant = true
  if (groupRecording) {
    isHostParticipant = participant.id === groupRecording.host.id
  }

  if (screenShareParticipant) {
    return (
      <Participant
        participant={screenShareParticipant}
        isLocalParticipant={isSharingScreen}
        isHostScreenShare={isHostParticipant}
        isScreenShare
        fill
      />
    )
  } else {
    return (
      <WaitingForHostParticipant
        message={'There was an error getting the screen share'}
      />
    )
  }
}
