import { Callback } from '../../../constants/types'
import EventEmitter from 'events'
import Video, { ConnectOptions, LocalTrack, Room } from 'twilio-video'
import { useCallback, useEffect, useRef, useState } from 'react'

// @ts-ignore
window.TwilioVideo = Video

export default function useRoom(
  localTracks: LocalTrack[],
  onError: Callback,
  setLoading: (value: boolean) => void,
  options?: ConnectOptions
) {
  const [room, setRoom] = useState<Room>(new EventEmitter() as Room)
  const [isConnecting, setIsConnecting] = useState(false)
  const optionsRef = useRef(options)

  useEffect(() => {
    // This allows the connect function to always access the most recent version of the options object. This allows us to
    // reliably use the connect function at any time.
    optionsRef.current = options
  }, [options])

  const connect = useCallback(
    (token) => {
      setIsConnecting(true)
      return Video.connect(token, {
        ...optionsRef.current,
        tracks: localTracks,
      }).then(
        (newRoom) => {
          setRoom(newRoom)

          // This app can add up to 13 'participantDisconnected' listeners to the room object, which can trigger
          // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
          newRoom.setMaxListeners(15)

          // @ts-ignore
          window.twilioRoom = newRoom

          newRoom.localParticipant.videoTracks.forEach((publication) =>
            // All video tracks are published with 'low' priority because the video track
            // that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            publication.setPriority('low')
          )

          setIsConnecting(false)

          // Stop loading
          setLoading(false)
        },
        (error) => {
          onError(error)
          setIsConnecting(false)
        }
      )
    },
    [localTracks, onError]
  )

  return { room, isConnecting, connect }
}
