import { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useUploadingContext from 'src/hooks/useUploadingContext/useUploadingContext'

import BusyUploading from './BusyUploading/BusyUploading'
import { useAppState } from 'src/state'
import UploadComplete from './UploadComplete/UploadComplete'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  container: {
    width: '500px',
  },
  button: {
    float: 'right',
  },
  dropZone: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  buttons: {
    margin: theme.spacing(2),
  },
}))

export default function RecordingStoppedDialog() {
  const classes = useStyles()
  const { groupRecording, participant } = useAppState()
  const {
    streamingUploadProgress,
    toggleUploadDialog,
    resetAssets,
    clearBlobs,
    resetProgress,
    resetTimerValue,
  } = useUploadingContext()
  const [showDialog, setShowDialog] = useState(true)

  const isHost =
    groupRecording && participant
      ? groupRecording.host.id === participant.id
      : true
  const isBusyUploading = !streamingUploadProgress.complete
  const isStreamUploadComplete = streamingUploadProgress.complete

  const continueClicked = () => {
    setShowDialog(false)
    if (isHost) {
      toggleUploadDialog()
    } else {
      resetAssets()
      clearBlobs()
      resetProgress()
      resetTimerValue()
    }
  }

  if (participant.isGuest) {
    return (
      <Dialog
        open={showDialog}
        onClose={setShowDialog}
        className={classes.root}
      >
        <DialogTitle>Recording complete</DialogTitle>
        <DialogContent className={classes.container}>
          As you joined as a guest, you do not need to upload anyhting.
        </DialogContent>
        <DialogActions className={classes.buttons}>
          {isStreamUploadComplete && (
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={continueClicked}
            >
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={showDialog}
      onClose={setShowDialog}
      className={classes.root}
    >
      <DialogTitle>
        {isBusyUploading && 'Finishing Upload'}
        {isStreamUploadComplete && 'Upload Complete'}
      </DialogTitle>
      <DialogContent className={classes.container}>
        {isBusyUploading && <BusyUploading isHost={isHost} />}

        {isStreamUploadComplete && <UploadComplete isHost={isHost} />}
      </DialogContent>
      <DialogActions className={classes.buttons}>
        {isStreamUploadComplete && (
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={continueClicked}
          >
            {isHost ? 'Continue' : 'Close'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
