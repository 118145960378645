import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import ScreenShareIcon from '@material-ui/icons/ScreenShare'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      height: 0,
      overflow: 'hidden',
      paddingTop: `${(9 / 16) * 100}%`,
      background: theme.palette.black.main,
      margin: theme.spacing(2),
      borderRadius: '4px',
      border: `3px solid rgb(255, 255, 255)`,
      [theme.breakpoints.down('sm')]: {
        height: 90,
        width: `${(90 * 16) / 9}px`,
        marginRight: '8px',
        marginBottom: '0',
        fontSize: '10px',
        paddingTop: `${90 - 2}px`,
      },
    },
    innerContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '4px',
    },
    avatarContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(40, 42, 43, 0.75)',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
    },
    typeography: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    avatar: {
      width: 60,
      height: 60,
      fontSize: '2rem',
      color: 'rgba(40, 42, 43, 0.75)',
    },
  })
)

interface WaitingForHostParticipantProps {
  message: string
}

// TODO: Move this and the participant waiting screen into one
export default function WaitingForHostParticipant({
  message,
}: WaitingForHostParticipantProps) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.avatarContainer}>
          <Avatar className={classes.avatar}>
            <ScreenShareIcon />
          </Avatar>
          <Typography
            variant="body1"
            className={classes.typeography}
            align="center"
          >
            {message}
          </Typography>
        </div>
      </div>
    </div>
  )
}
