import { Grid, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Lottie from 'lottie-react'

// @ts-ignore
import editingAnimation from 'src/assets/lottie-editing.json'
import useUploadingContext from 'src/hooks/useUploadingContext/useUploadingContext'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100vh',
  },
  textContainer: {
    color: '#FFFFFF',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
}))

export default function FinishedScreen() {
  const classes = useStyles()
  const { hadBlob } = useUploadingContext()

  return (
    <Grid
      className={classes.container}
      container
      justifyContent="center"
      alignContent="center"
    >
      <Grid item xs={3}>
        <Lottie animationData={editingAnimation} loop={true} autoPlay />
      </Grid>
      <Grid item sm={12} className={classes.textContainer}>
        <Typography variant="h4">Thank you for using Cloud Present</Typography>
      </Grid>

      {hadBlob && (
        <Grid item sm={12} className={classes.textContainer}>
          <Typography variant="h4">
            Our editors will start working their magic!
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}
