import { useAppState } from 'src/state'
import { Helmet } from 'react-helmet'

interface TitleProps {
  title?: string
  description?: string
}

export default function Title({ title, description }: TitleProps) {
  const { event, groupRecording } = useAppState()

  // Setup title
  let titleText = 'Cloud Present'
  if (title) {
    titleText = title
  } else if (event) {
    titleText = `${event.name} | Cloud Present`
  }

  // Setup description
  let descriptionText =
    'Cloud Present is a virtual recording platform that makes it easy to produce creative, professional video presentations anywhere!'
  if (description) {
    descriptionText = description
  } else if (groupRecording && event) {
    descriptionText = groupRecording
      ? `${event.name} - ${groupRecording.topic}`
      : event.name
  }

  return (
    <>
      <Helmet>
        <title>{titleText}</title>
        <meta property="og:title" content={titleText} />
        <meta name="twitter:title" content={titleText} />

        <meta name="description" content={descriptionText} />
        <meta property="og:description" content={descriptionText} />
        <meta name="twitter:description" content={descriptionText} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.cloudpresent.co" />
        <meta
          property="og:image"
          content="https://ucarecdn.com/b830e5bc-9305-4fbc-94fc-b2b1843d3f18/image.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://ucarecdn.com/b830e5bc-9305-4fbc-94fc-b2b1843d3f18/image.png"
        />
      </Helmet>
    </>
  )
}
