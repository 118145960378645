import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Participant from '../Participant/Participant'
import { useAppState } from 'src/state'
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'
import useParticipants from 'src/hooks/useParticipants/useParticipants'
import useMainParticipant from 'src/hooks/useMainParticipant/useMainParticipant'
import useUploadingContext from 'src/hooks/useUploadingContext/useUploadingContext'
import { Logger } from '@michaelprichardson/cloudpresent-core'
import { useEffect, useState } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
    },
    gridContainer: {
      width: '100%',
      height: '100%',
      display: 'grid',
      gridGap: theme.spacing(4),
      padding: theme.spacing(4),
    },
    gridContainerDiv: {
      overflow: 'hidden',
      minHeight: 0,
    },
    // 1 Participant
    grid1: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr 1fr 1fr',
    },
    grid1Item0: {
      gridColumn: '1 / span 1',
      gridRow: '2 / span 2',
    },
    // 2 Participants
    grid2: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr',
    },
    // 3 Participants
    grid3: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateRows: '1fr 1fr',
    },
    grid3Item0: {
      gridColumn: '1 / span 2',
      gridRow: '1',
    },
    grid3Item1: {
      gridColumn: '3 / span 2',
      gridRow: '1',
    },
    grid3Item2: {
      gridColumn: '2 / span 2',
      gridRow: '2',
    },
    // 4 Participants
    grid4: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr',
    },
    // 5 Participants
    grid5: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateRows: '1fr 1fr 1fr ',
    },
    grid5Item0: {
      gridColumn: '1 / span 2',
      gridRow: '1',
    },
    grid5Item1: {
      gridColumn: '3 / span 2',
      gridRow: '1',
    },
    grid5Item2: {
      gridColumn: '1 / span 2',
      gridRow: '2',
    },
    grid5Item3: {
      gridColumn: '3 / span 2',
      gridRow: '2',
    },
    grid5Item4: {
      gridColumn: '2 / span 2',
      gridRow: '3',
    },

    // 6 Participants
    grid6: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr 1fr',
    },
    // 7 Participants
    grid7: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateRows: '1fr 1fr 1fr 1fr',
    },
    grid7Item0: {
      gridColumn: '1 / span 2',
      gridRow: '1',
    },
    grid7Item1: {
      gridColumn: '3 / span 2',
      gridRow: '1',
    },
    grid7Item2: {
      gridColumn: '1 / span 2',
      gridRow: '2',
    },
    grid7Item3: {
      gridColumn: '3 / span 2',
      gridRow: '2',
    },
    grid7Item4: {
      gridColumn: '1 / span 2',
      gridRow: '3',
    },
    grid7Item5: {
      gridColumn: '3 / span 2',
      gridRow: '3',
    },
    grid7Item6: {
      gridColumn: '2 / span 2',
      gridRow: '4',
    },
    // 8 Participants
    grid8: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr 1fr 1fr',
    },
    // 9 Participants
    grid9: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridTemplateRows: '1fr 1fr 1fr 1fr 1fr',
    },
    grid9Item0: {
      gridColumn: '1 / span 2',
      gridRow: '1',
    },
    grid9Item1: {
      gridColumn: '3 / span 2',
      gridRow: '1',
    },
    grid9Item2: {
      gridColumn: '1 / span 2',
      gridRow: '2',
    },
    grid9Item3: {
      gridColumn: '3 / span 2',
      gridRow: '2',
    },
    grid9Item4: {
      gridColumn: '1 / span 2',
      gridRow: '3',
    },
    grid9Item5: {
      gridColumn: '3 / span 2',
      gridRow: '3',
    },
    grid9Item6: {
      gridColumn: '1 / span 2',
      gridRow: '4',
    },
    grid9Item7: {
      gridColumn: '3 / span 2',
      gridRow: '4',
    },
    grid9Item8: {
      gridColumn: '2 / span 2',
      gridRow: '5',
    },
    // 10 Participants
    grid10: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr 1fr 1fr 1fr',
    },
  })
)

// TODO: Move to a types file
interface HasVideo {
  id: string
  video: boolean
  name: string
  speaking: boolean
}

export default function ParticipantsScreenGrid() {
  const category = 'ParticipantsScreenGrid'
  const classes = useStyles()
  const {
    room: { localParticipant },
  } = useVideoContext()
  const { participant } = useAppState()
  const { participants: groupParticipants } = useUploadingContext()
  const participants = useParticipants()
  const speakingParticipant = useMainParticipant()
  const [numParticipants, setNumParticipants] = useState(0)
  const [isLocalSpeaking, setLocalSpeaking] = useState(false)
  const [hasVideos, setHasVideos] = useState<HasVideo[]>([])

  useEffect(() => {
    setNumParticipants(groupParticipants.length)
    setLocalSpeaking(participant.id === speakingParticipant.identity)

    Logger.debug(
      category,
      `Got ${groupParticipants.length} participants`,
      groupParticipants
    )

    const userVideos = groupParticipants
      .filter((it) => it.id !== participant.id)
      .map((it) => {
        return {
          id: it.id,
          video: !!participants.find((p) => p.identity === it.id),
          name: it.name,
          speaking: it.id === speakingParticipant.identity,
        }
      })
    setHasVideos(userVideos)

    Logger.log(category, 'userVideos', userVideos)
  }, [groupParticipants, participant, participants, speakingParticipant])

  const getLocalUserVideo = () => {
    return (
      <Participant
        participant={localParticipant}
        isLocalParticipant
        isDominantSpeaker={isLocalSpeaking}
        limitName
        fill
      />
    )
  }

  const getUserVideo = (userCheck: {
    id: string
    video: boolean
    name: string
    speaking: boolean
  }) => {
    const remoteParticipant = participants.find(
      (p) => p.identity === userCheck.id
    )
    Logger.log(
      category,
      `Checking user video (${userCheck.video}) and getting remoteParticipant`,
      remoteParticipant
    )
    if (userCheck.video && remoteParticipant) {
      return (
        <Participant
          participant={remoteParticipant}
          isDominantSpeaker={userCheck.speaking}
          limitName
          fill
        />
      )
    }
    return null
  }

  return (
    <div
      className={clsx(classes.gridContainer, classes[`grid${numParticipants}`])}
    >
      <div
        className={clsx(
          classes.gridContainerDiv,
          classes[`grid${numParticipants}Item0`]
        )}
      >
        {getLocalUserVideo()}
      </div>
      {Object.values(hasVideos).map((hasVideo, index) => {
        return (
          <div
            key={`video-screen-container-${hasVideo.id}`}
            className={clsx(
              classes.gridContainerDiv,
              classes[`grid${numParticipants}`],
              classes[`grid${numParticipants}Item${index + 1}`]
            )}
          >
            {getUserVideo(hasVideo)}
          </div>
        )
      })}
    </div>
  )
}
