import { createTheme } from '@material-ui/core'

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    sidebarWidth: number
    sidebarMobileHeight: number
    brand: string
    footerHeight: number
    mobileTopBarHeight: number
    mobileFooterHeight: number
    sidebarMobilePadding: number
    participantBorderWidth: number
    chatWindowWidth: number
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    sidebarWidth?: number
    sidebarMobileHeight?: number
    brand: string
    footerHeight: number
    mobileTopBarHeight: number
    mobileFooterHeight: number
    sidebarMobilePadding: number
    participantBorderWidth: number
    chatWindowWidth?: number
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    white: Palette['primary']
    black: Palette['primary']
    yellow: Palette['primary']
  }
  interface PaletteOptions {
    white: PaletteOptions['primary']
    black: PaletteOptions['primary']
    yellow: PaletteOptions['primary']
  }
}

const defaultTheme = createTheme()
const mainFont = ['Raleway', 'sans-serif'].join(',')
const bodyFont = ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(',')

export default createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        'html, body, #root': {
          height: '100%',
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: '4px',
        textTransform: 'none',
        color: 'rgb(40, 42, 43)',
        fontSize: '0.9rem',
        transition: defaultTheme.transitions.create(
          ['background-color', 'box-shadow', 'border', 'color'],
          {
            duration: defaultTheme.transitions.duration.short,
          }
        ),
      },
      text: {
        padding: '6px 14px',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      outlinedPrimary: {
        border: '2px solid #2D3958',
        '&:hover': {
          border: '2px solid rgb(1, 85, 137)',
        },
      },
      startIcon: {
        marginRight: '6px',
      },
    },
    MuiTypography: {
      body1: {
        color: 'rgb(40, 42, 43)',
        fontSize: '0.9rem',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '0.9rem',
      },
    },
    MuiSelect: {
      root: {
        padding: '0.85em',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '16px',
      },
    },
    MuiTextField: {
      root: {
        color: 'rgb(40, 42, 43)',
      },
    },
    MuiInputLabel: {
      root: {
        color: 'rgb(40, 42, 43)',
        fontSize: '1.1rem',
        marginBottom: '0.2em',
        fontWeight: 500,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: 'rgb(136, 140, 142)',
      },
    },
  },
  typography: {
    fontFamily: bodyFont,
    h1: {
      fontFamily: mainFont,
    },
    h2: {
      fontFamily: mainFont,
    },
    h3: {
      fontFamily: mainFont,
    },
    h4: {
      fontFamily: mainFont,
    },
    h5: {
      fontFamily: mainFont,
    },
    h6: {
      fontFamily: mainFont,
    },
  },
  palette: {
    primary: {
      main: '#2D3958',
    },
    secondary: {
      main: '#AD2831',
    },
    yellow: {
      main: '#EBC62D',
    },
    white: {
      main: '#FFFFFF',
    },
    black: {
      main: '#000000',
    },
  },
  brand: '#E22525',
  footerHeight: 72,
  mobileFooterHeight: 56,
  sidebarWidth: 300,
  sidebarMobileHeight: 90,
  sidebarMobilePadding: 8,
  participantBorderWidth: 2,
  mobileTopBarHeight: 52,
  chatWindowWidth: 320,
})
