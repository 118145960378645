import { LocalAudioTrack } from 'twilio-video'
import {
  FormControl,
  MenuItem,
  Typography,
  Select,
  FormHelperText,
} from '@material-ui/core'
import { SELECTED_AUDIO_INPUT_KEY } from 'src/constants/video'
import { useAudioInputDevices } from 'src/hooks/deviceHooks/deviceHooks'
import useMediaStreamTrack from 'src/hooks/useMediaStreamTrack/useMediaStreamTrack'
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'
import useLocalAudioToggle from 'src/hooks/useLocalAudioToggle/useLocalAudioToggle'

export default function AudioInputList() {
  const audioInputDevices = useAudioInputDevices()
  const { localTracks, updateAudioTrackId } = useVideoContext()
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle()

  const localAudioTrack = localTracks.find(
    (track) => track.kind === 'audio'
  ) as LocalAudioTrack
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack)
  const localAudioInputDeviceId =
    mediaStreamTrack?.getSettings().deviceId ||
    window.localStorage.getItem(SELECTED_AUDIO_INPUT_KEY)

  function replaceTrack(newDeviceId: string) {
    updateAudioTrackId(newDeviceId)
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId)

    if (isAudioEnabled) {
      localAudioTrack?.restart({ deviceId: { exact: newDeviceId } })
    } else {
      toggleAudioEnabled()
    }
  }

  return (
    <div>
      {audioInputDevices.length > 1 ? (
        <FormControl fullWidth>
          <Typography variant="subtitle2" gutterBottom>
            Audio Input
          </Typography>
          <Select
            onChange={(e) => replaceTrack(e.target.value as string)}
            value={localAudioInputDeviceId || ''}
            variant="outlined"
          >
            {audioInputDevices.map((device) => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            Select the microphone you would like to use
          </FormHelperText>
        </FormControl>
      ) : (
        <>
          <Typography variant="subtitle2" gutterBottom>
            Audio Input
          </Typography>
          <Typography>
            {localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}
          </Typography>
        </>
      )}
    </div>
  )
}
