import isPlainObject from 'is-plain-object'

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) {
    return obj
  }

  const target: { [name: string]: any } = {}

  for (const key in obj) {
    if (obj[key]) {
      const val = obj[key]
      if (typeof val !== 'undefined') {
        target[key] = removeUndefineds(val)
      }
    }
  }

  return target as T
}
