import {
  styled,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import MenuBar from 'src/components/MenuBar/MenuBar'
import HeaderBar from 'src/components/HeaderBar/HeaderBar'
import Room from 'src/components/Room/Room'
import PreJoinGroupRecordScreens from 'src/components/GroupRecordingSetupScreens/GroupRecordingSetupScreens'
import ReconnectingNotification from 'src/components/ReconnectingNotification/ReconnectingNotification'
import useRoomState from 'src/hooks/useRoomState/useRoomState'
import { useAppState } from 'src/state'
import FinishedScreen from 'src/components/FinishedScreen/FinishedScreen'

const Main = styled('main')(() => ({
  overflow: 'hidden',
}))

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
)

export default function RecordingContainer({ isGroup }: { isGroup: boolean }) {
  const classes = useStyles()
  const { isFetching, hasLeftSession } = useAppState()
  const roomState = useRoomState()

  if (hasLeftSession) {
    return (
      <>
        <Main>
          <FinishedScreen />
        </Main>
      </>
    )
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={isFetching}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {roomState === 'disconnected' ? (
        <>
          <PreJoinGroupRecordScreens isGroup={isGroup} />
        </>
      ) : (
        <>
          <Main>
            <ReconnectingNotification />
            <HeaderBar />
            <Room isGroup={isGroup} />
            <MenuBar />
          </Main>
        </>
      )}
    </>
  )
}
