import { makeStyles, Theme, Typography } from '@material-ui/core'
import LocalAudioLevelIndicator from '../../../LocalAudioLevelIndicator/LocalAudioLevelIndicator'
import { LocalVideoTrack } from 'twilio-video'
import VideoTrack from '../../../VideoTrack/VideoTrack'
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    height: 0,
    overflow: 'hidden',
    paddingTop: `${(9 / 16) * 100}%`,
    background: theme.palette.black.main,
    borderRadius: '4px',
  },
  innerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '4px',
  },
  gridImage: {
    position: 'absolute',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
    objectFit: 'fill',
    top: 0,
  },
  identityContainer: {
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
  },
  identity: {
    background: 'rgba(0, 0, 0, 0.75)',
    color: 'white',
    padding: theme.spacing(0.5),
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0px 4px 0px 0px',
  },
  microphoneContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 1,
  },
  microphone: {
    background: 'rgba(0, 0, 0, 0.75)',
    color: 'white',
    padding: theme.spacing(0.5),
    right: 0,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px 0px 0px 0px',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.black.main,
    color: theme.palette.white.main,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      '& svg': {
        transform: 'scale(0.7)',
      },
    },
  },
  icon: {
    width: 65,
    height: 65,
  },
}))

export default function LocalVideoPreview({ identity }: { identity: string }) {
  const classes = useStyles()
  const { localTracks } = useVideoContext()

  const videoTrack = localTracks.find((track) =>
    track.name.includes('camera')
  ) as LocalVideoTrack

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        {videoTrack ? (
          <VideoTrack track={videoTrack} isLocal />
        ) : (
          <div className={classes.iconContainer}>
            {/* <img src="/images/logo.svg" className={classes.icon} /> */}
            <Typography variant="body1" color="inherit" component="span">
              Please start your audio and video
            </Typography>
          </div>
        )}
      </div>

      <img
        src="/images/rule_of_thirds_white.png"
        className={classes.gridImage}
      />

      <div className={classes.identityContainer}>
        <span className={classes.identity}>
          <Typography variant="body1" color="inherit" component="span">
            {identity}
          </Typography>
        </span>
      </div>

      <div className={classes.microphoneContainer}>
        <span className={classes.microphone}>
          <LocalAudioLevelIndicator />
        </span>
      </div>
    </div>
  )
}
