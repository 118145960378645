import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { BACKGROUND } from 'src/constants/global'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${BACKGROUND})`,
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    width: '100vw',
  },
}))

export const Background = ({ children }: { children: any }) => {
  const classes = useStyles()

  return <div className={classes.container}>{children}</div>
}
