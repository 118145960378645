import { useState } from 'react'
import {
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'
import { useAppState } from 'src/state'

const END_SESSION_HOST_TEXT = 'End Session'
const END_SESSION_PARTICIPANT_TEXT = 'Leave Session'
const END_SESSION_HELPER_TEXT = 'You will be disconnected from the session'

const END_SESSION_MESSAGE_TEXT_SINGLE =
  'Are you sure you want to end the session?'
const END_SESSION_MESSAGE_TEXT_GROUP_HOST =
  'Are you sure you want to end the current session? This will end it for all participants.'
const END_SESSION_MESSAGE_TEXT_GROUP_PARTICIPANT =
  'Are you sure you want to leave the current session?'

interface EndSessionButtonProps {
  disabled?: boolean
  fullWidth?: boolean
}

export default function EndSessionButton({ fullWidth }: EndSessionButtonProps) {
  const { room } = useVideoContext()
  const { participant, groupRecording, leaveSession } = useAppState()
  const [confirmDialog, setConfirmDialog] = useState(false)

  let isHost = true
  if (groupRecording) {
    isHost = participant.id === groupRecording.host.id
  }

  const tooltipMessage = END_SESSION_HELPER_TEXT
  const buttonText = isHost
    ? END_SESSION_HOST_TEXT
    : END_SESSION_PARTICIPANT_TEXT
  let endMessage = END_SESSION_MESSAGE_TEXT_SINGLE
  if (groupRecording && isHost) {
    endMessage = END_SESSION_MESSAGE_TEXT_GROUP_HOST
  } else if (groupRecording && !isHost) {
    endMessage = END_SESSION_MESSAGE_TEXT_GROUP_PARTICIPANT
  }

  const endSession = () => {
    leaveSession()
    room.disconnect()
    setConfirmDialog(false)
  }

  return (
    <div>
      <Tooltip
        title={tooltipMessage}
        placement="top"
        PopperProps={{ disablePortal: true }}
      >
        <span>
          <Button
            onClick={() => setConfirmDialog(true)}
            startIcon={<CancelIcon />}
            color="secondary"
            variant="contained"
            fullWidth={fullWidth}
          >
            {buttonText}
          </Button>
        </span>
      </Tooltip>

      <Dialog
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        aria-labelledby="disconnect-alert-dialog-title"
        aria-describedby="disconnect-alert-dialog-description"
      >
        <DialogTitle id="disconnect-alert-dialog-title">
          {buttonText}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {endMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDialog(false)}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button onClick={endSession} color="secondary" variant="contained">
            {isHost ? 'End' : 'Leave'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
