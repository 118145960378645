import { useState, useCallback, useEffect } from 'react'
import { useAppState } from 'src/state'

export default function useUploadDialog(
  isGroup: boolean,
  resetTimerValue: () => void
) {
  const { participant, groupRecording } = useAppState()
  const [isHost, setHost] = useState(false)
  const [haveParticipant, setHaveParticipant] = useState(false)
  const [uploadDialog, setUploadDialog] = useState(false)

  // This will set uploading for the remote users to show that the host is busy
  useEffect(() => {
    // Only when the participant is set the figure out of they are the host
    if (!haveParticipant && participant && isGroup) {
      setHost(participant.id === groupRecording.host.id)
      setHaveParticipant(true)
    } else if (!haveParticipant && participant && !isGroup) {
      setHost(true)
      setHaveParticipant(true)
    }
  }, [participant, isGroup])

  // Callback to toggle the dialog for the host
  const toggleUploadDialog = useCallback(() => {
    if (isHost) {
      setUploadDialog(!uploadDialog)
    }
  }, [uploadDialog, isHost])

  const closeUploadDialog = useCallback(() => {
    if (isHost) {
      setUploadDialog(false)
      resetTimerValue()
    }
  }, [isHost])

  return { uploadDialog, toggleUploadDialog, closeUploadDialog }
}
