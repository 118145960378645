import { useState, useEffect } from 'react'
import { attachHostParticipantListener, detachHostParticipantListener, Participant } from '@michaelprichardson/cloudpresent-core'
import { useAppState } from 'src/state'

export default function useCheckForHostParticipant(
  isGroup: boolean,
  participant?: Participant
) {
  const { event, groupRecording, setUploadId } = useAppState()
  const [isParticipant, setParticipant] = useState(false)
  const [hostPresent, setHostPresent] = useState(false)
  const [sessionLocked] = useState(false)
  const [haveParticipant, setHaveParticipant] = useState(false)

  useEffect(() => {
    // Only when the participant is set the figure out of they are the host
    if (participant && isGroup) {
      setParticipant(participant.id !== groupRecording.host.id)
      setHaveParticipant(true)
    } else {
      setParticipant(false)
      setHaveParticipant(false)
    }
  }, [participant, isGroup])

  useEffect(() => {
    // If there is a participant and we know if they are the host or not then either listen for updates or set the default
    if (haveParticipant) {
      attachHostParticipantListener(event.id, groupRecording.id, (hostPresent: boolean, uploadId?: string) => {
        setHostPresent(hostPresent)
        if (uploadId) setUploadId(uploadId)
      })
    }

    return () => {
      // Detach the listener if there is one
      if (haveParticipant) {
        detachHostParticipantListener(event.id, groupRecording.id)
      }
    }
  }, [isParticipant, haveParticipant])

  return { isParticipant, hostPresent, sessionLocked }
}
