import { Typography } from '@material-ui/core'

export default function ConfirmUploadDialogError({ error }: { error: string }) {
  if (error === 'zipping-timeout') {
    return (
      <div>
        <Typography variant="body1">
          Zipping your presentation files took too long (5 minutes max). We have
          the camera and screen recordings.
        </Typography>
        <Typography variant="body1" style={{ paddingTop: '8px' }}>
          Please get in contact with us at hello@cloudpresent.co so we can get
          the files from you.
        </Typography>
      </div>
    )
  } else if (error === 'uploading-timeout' || error === 'upload-network') {
    return (
      <div>
        <Typography variant="body1">
          We could not connect to the Cloud Present servers, please retry.
        </Typography>
        <Typography variant="body1" style={{ paddingTop: '8px' }}>
          If the problem persists, download the camera and screen recordings so
          you don't lose them.
        </Typography>
        <Typography variant="body1" style={{ paddingTop: '8px' }}>
          Contact us at hello@cloudpresent.co so we can get the files from you.
        </Typography>
      </div>
    )
  } else {
    return (
      <div>
        <Typography variant="body1">
          There was an error uploading your recordings{` (Code: ${error})`}.
          Please try again or download the recordings to your computer so you
          don't lose them.
        </Typography>
        <Typography variant="body1" style={{ paddingTop: '8px' }}>
          Contact us at hello@cloudpresent.co if the problem persists.
        </Typography>
      </div>
    )
  }
}
