import { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import AboutDialog from 'src/components/AboutDialog/AboutDialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: '#FFFFFF',
    },
  })
)

export default function HelpButton() {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  return (
    <div>
      <IconButton
        aria-label="help"
        className={classes.icon}
        onClick={() => setOpen(true)}
      >
        <HelpIcon />
      </IconButton>

      <AboutDialog open={open} onClose={() => setOpen(false)} />
    </div>
  )
}
