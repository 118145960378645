import { PropsWithChildren, useState } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Video from 'twilio-video'
// @ts-ignore
import packageJson from '../../../package.json'
import ReportButton from '../Buttons/ReportButton/ReportButton'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    question: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    answer: {
      color: '#AAAAAA',
    },
    accordion: {
      boxShadow: 'none',
      border: 'none',
    },
    titleImage: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      height: '50px',
      objectFit: 'contain',
      marginBottom: '24px',
    },
  })
)

interface AboutDialogProps {
  open: boolean
  onClose(): void
}

function AboutDialog({ open, onClose }: PropsWithChildren<AboutDialogProps>) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  const faqs = [
    {
      question: 'Can I redo my recordings?',
      answer:
        'Yes, just stop your current recording, once it has finished uploading you can leave a message for our editors to ignore the recording and click restart recording.',
    },
    {
      question: "I can't see myself?",
      answer:
        'Make sure you have a camera connect and have given Cloud Present access to your camera. You can usually see the permissions in the address bar.',
    },
    {
      question: "I can't add any assets?",
      answer:
        'If you have shared your screen, you will be required to upload a presentation file. This is to ensure we can mkae you presentation as hihg quiality as possible. You can upload any file format you want. There is a limit of 10 files, each with a size limit of 1GB.',
    },
  ]

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <img
          className={classes.titleImage}
          src="/images/full_logo_dark.png"
          alt="Cloud Present Logo"
        />
        <br />
        {faqs.map((faq, index) => {
          return (
            <Accordion
              key={index}
              className={classes.accordion}
              expanded={expanded === `faq_${index}`}
              onChange={handleChange(`faq_${index}`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.question}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.answer}>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          )
        })}
        <br />
        <DialogContentText variant="body1">
          If your issue is still not resolved, feel free to email us at{' '}
          <a href="mailto:hello@cloudpresent.co">hello@cloudpresent.co</a>
        </DialogContentText>
        <br />
        <DialogContentText variant="caption">
          SDK Version: {Video.version}
        </DialogContentText>
        <DialogContentText variant="caption">
          Platform Version: {packageJson.version}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <ReportButton />
        <Button onClick={onClose} color="primary" variant="contained" autoFocus>
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AboutDialog
