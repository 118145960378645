import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import useRoomState from '../../hooks/useRoomState/useRoomState'
import useUploadingContext from 'src/hooks/useUploadingContext/useUploadingContext'
import { Grid, Hidden } from '@material-ui/core'
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton'
import ToggleRecordingButton from '../Buttons/ToggleRecordingButton/ToggleRecordingButton'
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToogleScreenShareButton'
import EndSessionButton from '../Buttons/EndSessionButton/EndSessionButton'
import CircularProgressBar from 'src/components/CircularProgressBar/CircularProgressBar'
import { useAppState } from 'src/state'
import SettingsButton from '../Buttons/SettingsButton/SettingsButton'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'transparent',
      borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px 0px 0px`,
      bottom: 0,
      left: 0,
      right: 0,
      height: `72px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      marginBottom: theme.spacing(2),
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `56px`,
        padding: 0,
      },
    },
    actionContainer: {
      backgroundColor: 'white',
      borderRadius: theme.spacing(1),
      boxShadow: `0 ${theme.spacing(2)}px ${theme.spacing(
        4
      )}px rgba(0,0,0,0.8)`,
      padding: theme.spacing(1),
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 10,
      bottom: `72px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    muteButton: {
      color: theme.palette.black.main,
      backgroundColor: theme.palette.white.main,
      '&:hover': {
        backgroundColor: '#AAAAAA',
        borderColor: '#AAAAAA',
      },
    },
    headingText: {
      color: theme.palette.primary.main,
    },
  })
)

export default function MenuBar() {
  const classes = useStyles()
  const roomState = useRoomState()
  const { participant, groupRecording } = useAppState()
  const { streamingUploadProgress } = useUploadingContext()

  const isReconnecting = roomState === 'reconnecting'
  let isHost = true
  if (groupRecording) {
    isHost = participant.id === groupRecording.host.id
  }

  return (
    <>
      <footer className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden smDown>
            <Grid style={{ flex: 1 }}></Grid>
          </Hidden>
          <Grid item className={classes.actionContainer}>
            <Grid container justifyContent="center" spacing={1}>
              <Grid item>
                <ToggleAudioButton
                  icon
                  disabled={isReconnecting}
                  className={classes.muteButton}
                  customIcon={true}
                  variant={'contained'}
                  fullWidth
                />
              </Grid>

              <Grid item>
                <ToggleScreenShareButton icon disabled={isReconnecting} />
              </Grid>

              {isHost && (
                <Grid item style={{ marginTop: '6px' }}>
                  <ToggleRecordingButton fullWidth />
                </Grid>
              )}

              <Grid item>
                <div style={{ paddingTop: '6px' }}>
                  <CircularProgressBar
                    width={35}
                    height={35}
                    progress={streamingUploadProgress}
                    allowUpload={!participant.isGuest}
                  />
                </div>
              </Grid>

              <Grid item>
                <SettingsButton />
              </Grid>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid container justifyContent="flex-end">
                <EndSessionButton />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  )
}
