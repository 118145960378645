import { useEffect } from 'react'
import { useAppState } from 'src/state'
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'
import { addScreenShareTimeCode } from '@michaelprichardson/cloudpresent-core'

export default function useScreenShareTimeCodes(recordingNumber: number) {
  const { participant, uploadId } = useAppState()
  const { isSharingScreen } = useVideoContext()

  useEffect(() => {
    const addTimeCode = async () => {
      // Add timestamp for screen share
      if (participant && uploadId) {
        await addScreenShareTimeCode(
          uploadId,
          participant.id,
          recordingNumber === 0 ? recordingNumber + 1 : recordingNumber,
          isSharingScreen
        )
      }
    }

    addTimeCode()
  }, [isSharingScreen])
}
