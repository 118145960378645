import React, {
  createContext,
  useContext,
  useReducer,
  useState,
  useEffect,
} from 'react'
import { RoomType } from '../constants/types'
import { TwilioError } from 'twilio-video'
import {
  settingsReducer,
  initialSettings,
  Settings,
  SettingsAction,
} from './settings/settingsReducer'
import useActiveSinkId from './useActiveSinkId/useActiveSinkId'
import {
  createTwilioToken,
  getEvent as fetchEvent,
  getGroupRecording,
  User,
} from '@michaelprichardson/cloudpresent-core'
import type { Event, GroupRecording, Participant } from '@michaelprichardson/cloudpresent-core'
import md5 from 'md5'
import * as Sentry from '@sentry/react'

export interface StateContextType {
  error: TwilioError | Error | null
  setError(error: TwilioError | Error | null): void
  getToken(
    groupId: string,
    email: string,
    userId: string,
    userName: string,
    isGuest: boolean,
    eventId?: string,
    uploadId?: string
  ): Promise<{ token: string; uploadId: string }>
  getEvent(companyName: string, eventName: string): Promise<Event>
  getGroup(
    companyName: string,
    eventName: string,
    groupTopic: string
  ): Promise<GroupRecording>
  setIsGroup(isGroup: boolean): void
  user?:
    | User
    | null
    | { displayName: undefined; photoURL: undefined; passcode?: string }
  userId?: string
  userEmail?: string
  setUserEmail(id: string): void
  setHasAlreadyJoined(value: boolean): void
  restart(): void
  signIn?(passcode?: string): Promise<void>
  signOut?(): Promise<void>
  isAuthReady?: boolean
  event: Event
  groupRecording: GroupRecording
  participant: Participant
  uploadId?: string
  currentSession: number
  setCurrentSession(value: number): void
  setUploadId(id: string): void
  isFetching: boolean
  setIsFetching(value: boolean): void
  activeSinkId: string
  setActiveSinkId(sinkId: string): void
  settings: Settings
  dispatchSetting: React.Dispatch<SettingsAction>
  roomType: RoomType
  canContinue: boolean
  hasAlreadyJoined: boolean
  invalidEvent: boolean
  hasLeftSession: boolean
  leaveSession(): void
}

export const StateContext = createContext<StateContextType>(null!)

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | Error | null>(null)
  const [invalidEvent, setInvalidEvent] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [event, setEvent] = useState<Event>()
  const [groupRecording, setGroupRecording] = useState<GroupRecording>()
  const [roomType, setRoomType] = useState<RoomType>()
  const [participant, setParticipant] = useState<Participant>()
  const [userId, setUserId] = useState<string>()
  const [userEmail, setEmail] = useState<string>()
  const [uploadId, setUploadId] = useState<string>()
  const [currentSession, setCurrentSession] = useState(0)
  const [canContinue, setCanContinue] = useState(false)
  const [hasAlreadyJoined, setHasAlreadyJoined] = useState(false)
  const [activeSinkId, setActiveSinkId] = useActiveSinkId()
  const [hasLeftSession, setLeftSession] = useState(false)
  const [settings, dispatchSetting] = useReducer(
    settingsReducer,
    initialSettings
  )

  let contextValue = {
    invalidEvent,
    error,
    setError,
    event,
    groupRecording,
    roomType,
    participant,
    uploadId,
    setUploadId,
    currentSession,
    setCurrentSession,
    isFetching,
    setIsFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    canContinue,
    userId,
    userEmail,
    hasAlreadyJoined,
    setHasAlreadyJoined,
    hasLeftSession,
  } as StateContextType

  useEffect(() => {
    if (error && !invalidEvent) {
      setInvalidEvent(true)
    }
  }, [error, invalidEvent])

  contextValue = {
    ...contextValue,
    getToken: async (groupId, _, uid, userName, isGuest, eventId, hostUploadId) => {
      return createTwilioToken(eventId!, groupId, hostUploadId!, uid, userName)
    },
    getEvent: async (company, event) => {
      return await fetchEvent(company, event)
    },
    getGroup: async (companyName, eventName, groupTopic) => {
      return await getGroupRecording(companyName, eventName, groupTopic)
    },
    setIsGroup: (isGroup) => {
      setRoomType(isGroup ? 'group' : 'peer-to-peer')
    },
    restart: () => {
      setUserId(undefined)
      setUploadId(undefined)
      setParticipant(undefined)
      setHasAlreadyJoined(false)
    },
    leaveSession: () => {
      setLeftSession(true)
    },
  }

  const getToken: StateContextType['getToken'] = async (
    groupId,
    email,
    uid,
    userName,
    isGuest,
    eventId,
    hostUploadId
  ) => {
    setIsFetching(true)
    Sentry.setUser({ email })
    setUserId(uid)
    const res = await contextValue.getToken(
      groupId,
      email,
      uid,
      userName,
      isGuest,
      eventId,
      hostUploadId
    )
    setParticipant({
      id: uid,
      email,
      name: userName,
      isGuest,
    })

    // TODO: Figure out a cleaner way to do this
    if (uid === groupRecording?.host.id || eventId) {
      setUploadId(res.uploadId)
    }
    // setIsFetching(false)
    return res
  }

  const setUserEmail = (email: string) => {
    Sentry.setUser({ email })
    setEmail(email)
    setUserId(md5(email))
  }

  const getEvent: StateContextType['getEvent'] = async (company, event) => {
    setIsFetching(true)
    const res = await contextValue.getEvent(company, event)
    setEvent(res)
    setCanContinue(true)
    setIsFetching(false)
    return res
  }

  const getGroup: StateContextType['getGroup'] = async (
    companyName,
    eventName,
    groupTopic
  ) => {
    setIsFetching(true)
    const res = await contextValue.getGroup(companyName, eventName, groupTopic)
    setGroupRecording(res)
    setCanContinue(true)
    setIsFetching(false)
    return res
  }

  const setIsGroup: StateContextType['setIsGroup'] = (isGroup) => {
    contextValue.setIsGroup(isGroup)
  }

  const restart: StateContextType['restart'] = () => {
    contextValue.restart()
  }

  return (
    <StateContext.Provider
      value={{
        ...contextValue,
        getToken,
        getEvent,
        getGroup,
        setIsGroup,
        setUserEmail,
        restart,
      }}
    >
      {props.children}
    </StateContext.Provider>
  )
}

export function useAppState() {
  const context = useContext(StateContext)
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider')
  }
  return context
}
