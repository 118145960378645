import { Typography } from '@material-ui/core'
import { formatBytes } from 'src/utils/bytes'
import { IUploadTask } from 'src/constants/types'
import useUploadingContext from 'src/hooks/useUploadingContext/useUploadingContext'

export default function ConfirmUploadDialogProgress() {
  const { uploadProgress } = useUploadingContext()

  const capitalize = (s: string) => {
    if (s.length === 0) {
      return ''
    }
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  const getProgressView = (title: string, progress: IUploadTask) => {
    const percentage = Math.round(progress.progress)
    if (progress.pending) {
      return (
        <Typography variant="body1" style={{ textAlign: 'center' }}>
          {`Pending ${title}...`}
        </Typography>
      )
    } else if (progress.processing) {
      return (
        <Typography variant="body1" style={{ textAlign: 'center' }}>
          {`Busy processing ${title}...`}
        </Typography>
      )
    } else if (percentage === 100.0) {
      return (
        <Typography variant="body1" style={{ textAlign: 'center' }}>
          {`${capitalize(title)} uploaded successfully`}
        </Typography>
      )
    } else {
      return (
        <Typography variant="body1" style={{ textAlign: 'center' }}>
          {`Uploading ${title} ${percentage.toFixed(0)}% (${formatBytes(
            progress.total
          )})`}
        </Typography>
      )
    }
  }

  return <div>{getProgressView('asset files', uploadProgress)}</div>
}
