import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import {
  LocalAudioTrack,
  LocalVideoTrack,
  Participant,
  RemoteAudioTrack,
  RemoteVideoTrack,
} from 'twilio-video'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'

import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator'
import AvatarIcon from '../../icons/AvatarIcon'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel'
import Typography from '@material-ui/core/Typography'

import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff'
import usePublications from '../../hooks/usePublications/usePublications'
import useTrack from '../../hooks/useTrack/useTrack'
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting'
import { useAppState } from 'src/state'
import useUploadingContext from 'src/hooks/useUploadingContext/useUploadingContext'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: 0,
      overflow: 'hidden',
      paddingTop: `calc(${(9 / 16) * 100}%)`,
      background: 'black',
      [theme.breakpoints.down('sm')]: {
        height: 90,
        width: `${(90 * 16) / 9}px`,
        fontSize: '10px',
      },
    },
    containerPaddingBorder: {
      margin: theme.spacing(2),
      '& video': {
        filter: 'none',
      },
      borderRadius: theme.spacing(1),
      border: `3px solid rgb(255, 255, 255)`,
      [theme.breakpoints.down('sm')]: {
        marginRight: '8px',
        marginBottom: '0',
        paddingTop: `${90 - 2}px`,
      },
    },
    innerContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%',
      background: 'transparent',
      top: 0,
    },
    gridImage: {
      position: 'absolute',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%',
      objectFit: 'fill',
      top: 0,
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'black',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        '& svg': {
          transform: 'scale(0.7)',
        },
      },
    },
    reconnectingContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(40, 42, 43, 0.75)',
      zIndex: 1,
    },
    identity: {
      background: 'rgba(0, 0, 0, 0.75)',
      color: 'white',
      padding: theme.spacing(0.5),
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      borderRadius: `0px ${theme.spacing(1)}px 0px 0px`,
    },
    infoRowBottom: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
    microphoneContainer: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 1,
    },
    microphone: {
      background: 'rgba(0, 0, 0, 0.75)',
      color: 'white',
      padding: theme.spacing(0.5),
      right: 0,
      display: 'flex',
      alignItems: 'center',
      borderRadius: `${theme.spacing(1)}px 0px 0px 0px`,
    },
    fullscreenContainer: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 5,
    },
    fullscreen: {
      background: 'rgba(0, 0, 0, 0.75)',
      color: 'white',
      padding: theme.spacing(0.5),
      right: 0,
      display: 'flex',
      alignItems: 'center',
      borderRadius: `${theme.spacing(1)}px 0px 0px 0px`,
      cursor: 'pointer',
    },
    networkQualityContainer: {
      width: '28px',
      height: '28px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0, 0, 0, 0.75)',
      borderRadius: `0px 0px ${theme.spacing(1)}px 0px`,
    },
    typeography: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      color: 'white',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    hideParticipant: {
      display: 'none',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    recordingBorder: {
      borderColor: theme.palette.secondary.main,
    },
    speakingBorder: {
      borderColor: theme.palette.yellow.main,
    },
    shadow: {
      boxShadow: `0 ${theme.spacing(2)}px ${theme.spacing(
        4
      )}px rgba(0,0,0,0.8)`,
    },
  })
)

interface ParticipantInfoProps {
  participant: Participant
  children: React.ReactNode
  onClick?: () => void
  isSelected?: boolean
  isLocalParticipant?: boolean
  hideParticipant?: boolean
  isHostScreenShare?: boolean
  isScreenShare?: boolean
  isDominantSpeaker?: boolean
  limitName?: boolean
}

export default function ParticipantInfo({
  participant,
  onClick,
  children,
  isLocalParticipant,
  hideParticipant,
  isHostScreenShare,
  isScreenShare,
  limitName,
}: ParticipantInfoProps) {
  const publications = usePublications(participant)
  const {
    participant: currentParticipant,
    groupRecording,
    roomType,
  } = useAppState()
  const { isRecording, participants } = useUploadingContext()
  const [participantName, setParticipantName] = useState('')

  // Full screen
  const handle = useFullScreenHandle()

  const audioPublication = publications.find((p) => p.kind === 'audio')
  const videoPublication = publications.find((p) =>
    p.trackName.includes('camera')
  )

  const isVideoEnabled = Boolean(videoPublication)
  const videoTrack = useTrack(videoPublication)
  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack
  )

  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined
  const isParticipantReconnecting = useParticipantIsReconnecting(participant)

  let isHost = true
  let isHostParticipant = true
  if (groupRecording) {
    isHostParticipant = participant.identity === groupRecording.host.id
    isHost = currentParticipant.id === groupRecording.host.id
  }

  const classes = useStyles()

  useEffect(() => {
    const currentParticipant = participants.find(
      (p) => p.id === participant.identity
    )

    if (currentParticipant) {
      setParticipantName(currentParticipant.name)
    }
  }, [groupRecording])

  const formatTitle = (name: string): string => {
    if (limitName) {
      if (isLocalParticipant) {
        return 'You'
      } else if (!isLocalParticipant && isHostParticipant) {
        return 'Host'
      }
      return name.length < 10 ? name : `${name.substring(0, 10)}...`
    } else if (isLocalParticipant) {
      return `${name} (You)`
    } else if (!isLocalParticipant && isHostParticipant) {
      return `${name} (Host)`
    }
    return name
  }

  return (
    <FullScreen handle={handle}>
      <div
        className={clsx(classes.container, classes.shadow, {
          [classes.containerPaddingBorder]: !handle.active,
          [classes.hideParticipant]: hideParticipant,
          [classes.cursorPointer]: Boolean(onClick),
          [classes.recordingBorder]: isRecording,
        })}
        onClick={onClick}
        data-cy-participant={participant.identity}
      >
        <div className={classes.infoContainer}>
          {!isHostScreenShare && roomType === 'group' && (
            <div className={classes.networkQualityContainer}>
              <NetworkQualityLevel participant={participant} />
            </div>
          )}

          <div className={classes.infoRowBottom}>
            {isScreenShare && (
              <span className={classes.identity}>
                <Typography
                  variant="body1"
                  className={classes.typeography}
                  component="span"
                >
                  {isLocalParticipant ? 'Your' : `${participantName}'s`}
                  {' Screen'}
                </Typography>
              </span>
            )}

            {!isScreenShare && (
              <span className={classes.identity}>
                <Typography
                  variant="body1"
                  className={classes.typeography}
                  component="span"
                >
                  {formatTitle(participantName)}
                </Typography>
              </span>
            )}
          </div>
        </div>

        {!isScreenShare && (
          <div className={classes.microphoneContainer}>
            <span className={classes.microphone}>
              <AudioLevelIndicator
                audioTrack={audioTrack}
                onlyShowMute={!isLocalParticipant && !isHost}
              />
            </span>
          </div>
        )}

        {isScreenShare && !isLocalParticipant && (
          <div className={classes.fullscreenContainer}>
            <span
              className={classes.fullscreen}
              onClick={handle.active ? handle.exit : handle.enter}
            >
              {handle.active ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </span>
          </div>
        )}

        {!isHostScreenShare && (isLocalParticipant || isHost) && (
          <img
            src="/images/rule_of_thirds_white.png"
            className={classes.gridImage}
            alt="Rule of Thirds"
          />
        )}

        <div className={classes.innerContainer}>
          {(!isVideoEnabled || isVideoSwitchedOff) && (
            <div className={classes.avatarContainer}>
              <AvatarIcon />
            </div>
          )}
          {isParticipantReconnecting && (
            <div className={classes.reconnectingContainer}>
              <Typography variant="body1" className={classes.typeography}>
                Reconnecting...
              </Typography>
            </div>
          )}
          {children}
        </div>
      </div>
    </FullScreen>
  )
}
