import { useEffect, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import ScreenShareIcon from '@material-ui/icons/ScreenShare'
import StopScreenShareIcon from '@material-ui/icons/StopScreenShare'
import Cookies from 'universal-cookie'

import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'
import useScreenShareParticipant from 'src/hooks/useScreenShareParticipant/useScreenShareParticipant'
import { useAppState } from 'src/state'
import useUploadingContext from 'src/hooks/useUploadingContext/useUploadingContext'
import { SCREEN_SHARE_TIPS } from 'src/constants/global'
import ScreenShareDialog from 'src/components/ScreenShareDialog/ScreenShareDialog'
import { Logger } from '@michaelprichardson/cloudpresent-core'

export const START_SCREEN_SHARE_TEXT = 'Start Screen Share'
export const STOP_SCREEN_SHARE_TEXT = 'Stop Screen Share'
export const RECORD_IN_PROGRESS_TEXT = 'Cannot stop screen share when recording'
export const SHARE_NOT_SUPPORTED_TEXT =
  'Screen sharing is not supported with this browser'
export const SOMEONE_ELSE_SHARING_TEXT = 'Someone else is sharing their screen'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.black.main,
      backgroundColor: theme.palette.white.main,
      '&:hover': {
        backgroundColor: '#AAAAAA',
        borderColor: '#AAAAAA',
      },
    },
  })
)

interface ToggleScreenShareButtonProps {
  icon?: boolean
  disabled?: boolean
  fullWidth?: boolean
}

export default function ToggleScreenShareButton({
  icon,
  disabled,
  fullWidth,
}: ToggleScreenShareButtonProps) {
  const category = 'ToggleScreenShareButton'
  const classes = useStyles()
  const { groupRecording } = useAppState()
  const { isRecording } = useUploadingContext()
  const { isSharingScreen, toggleScreenShare } = useVideoContext()
  const { screenShareParticipant } = useScreenShareParticipant()
  const [showScreenShareTips, setShowScreenShareTips] = useState(true)
  const [showingScreenShareTips, setShowingScreenShareTips] = useState(false)

  useEffect(() => {
    const cookies = new Cookies()
    const cookieValue = cookies.get(SCREEN_SHARE_TIPS)
    const show = cookieValue === undefined || cookieValue === true
    Logger.log(
      category,
      `Got ${SCREEN_SHARE_TIPS} cookie value ${cookieValue} using ${show}`
    )
    setShowScreenShareTips(show)
  }, [])

  // @ts-ignore
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia
  const isDisabled =
    disabled ||
    (isRecording && !groupRecording) ||
    !isScreenShareSupported ||
    (Boolean(screenShareParticipant) && !isSharingScreen)

  let tooltipMessage = START_SCREEN_SHARE_TEXT

  if (isRecording && !groupRecording) {
    tooltipMessage = RECORD_IN_PROGRESS_TEXT
  } else if (isSharingScreen) {
    tooltipMessage = SOMEONE_ELSE_SHARING_TEXT
  } else if (screenShareParticipant) {
    tooltipMessage = SOMEONE_ELSE_SHARING_TEXT
  } else if (!isScreenShareSupported) {
    tooltipMessage = SHARE_NOT_SUPPORTED_TEXT
  }

  const screenShareClicked = () => {
    if (isSharingScreen) {
      toggleScreenShare()
    } else if (showScreenShareTips) {
      setShowingScreenShareTips(true)
    } else {
      shareScreen(true)
    }
  }

  const shareScreen = (showTips: boolean) => {
    Logger.log(category, `Setting ${SCREEN_SHARE_TIPS} to ${showTips}`)
    const cookies = new Cookies()
    cookies.set(SCREEN_SHARE_TIPS, showTips, { maxAge: 7 * 24 * 60 * 60 })
    setShowScreenShareTips(showTips)
    setShowingScreenShareTips(false)
    toggleScreenShare()
  }

  return (
    <>
      <Tooltip
        title={tooltipMessage}
        placement="top"
        PopperProps={{ disablePortal: true }}
        style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      >
        <span>
          {icon ? (
            <IconButton
              size="medium"
              color="primary"
              aria-label="share screen"
              onClick={screenShareClicked}
              disabled={isDisabled}
            >
              {isSharingScreen ? <StopScreenShareIcon /> : <ScreenShareIcon />}
            </IconButton>
          ) : (
            <Button
              className={classes.button}
              onClick={screenShareClicked}
              disabled={isDisabled}
              startIcon={
                isSharingScreen ? <StopScreenShareIcon /> : <ScreenShareIcon />
              }
              color="primary"
              variant="contained"
              fullWidth={fullWidth}
            >
              {isSharingScreen
                ? STOP_SCREEN_SHARE_TEXT
                : START_SCREEN_SHARE_TEXT}
            </Button>
          )}
        </span>
      </Tooltip>

      <ScreenShareDialog
        open={showingScreenShareTips}
        onCancel={() => setShowingScreenShareTips(false)}
        onShare={shareScreen}
      />
    </>
  )
}
