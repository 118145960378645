import clsx from 'clsx'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Typography, Grid } from '@material-ui/core'
import { useAppState } from 'src/state'
import useUploadingContext from 'src/hooks/useUploadingContext/useUploadingContext'
import HelpButton from 'src/components/Buttons/HelpButton/HelpButton'
import RecordTimer from '../RecordTimer/RecordTimer'
import Lottie from 'lottie-react'
// @ts-ignore
import recordingAnimation from 'src/assets/lottie-recording-dot.json'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'transparent',
      top: 0,
      left: 0,
      right: 0,
      height: `72px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `56px`,
        padding: 0,
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    logo: {
      paddingTop: theme.spacing(1),
      marginRight: theme.spacing(2),
      height: '50px',
      width: '50px',
    },
    headerText: {
      color: '#FFFFFF',
    },
    singlePadding: {
      paddingTop: theme.spacing(1.5),
    },
  })
)

export default function HeaderBar() {
  const classes = useStyles()
  const { event, groupRecording } = useAppState()
  const { isRecording } = useUploadingContext()

  return (
    <>
      <header className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Grid style={{ flex: 1 }}>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <div className={classes.logo}>
                  <img src="/images/logo.svg" alt="Cloud Present Logo" />
                </div>
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  className={clsx(classes.headerText, {
                    [classes.singlePadding]: !groupRecording,
                  })}
                >
                  {`${event?.company} - ${event?.name}`}
                  {groupRecording && (
                    <Typography variant="body1" color="inherit">
                      {groupRecording.topic}
                    </Typography>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ flex: 1 }}>
            <Grid container justifyContent="center">
              {isRecording && (
                <div style={{ height: '40px', width: '40px' }}>
                  <Lottie
                    animationData={recordingAnimation}
                    loop={isRecording}
                    autoPlay
                  />
                </div>
              )}
              <Typography variant="h4" className={classes.headerText}>
                <RecordTimer />
              </Typography>
            </Grid>
          </Grid>
          <Grid style={{ flex: 1 }}>
            <Grid container justifyContent="flex-end">
              <HelpButton />
            </Grid>
          </Grid>
        </Grid>
      </header>
    </>
  )
}
