import { Grid, Typography } from '@material-ui/core'

export default function UploadComplete({ isHost }: { isHost: boolean }) {
  return (
    <Grid container justifyContent="center">
      <Grid item sm={12}>
        {isHost ? (
          <Typography variant="body1">
            Your upload is complete. Next you can upload assets for your
            recording and provide any notes for our editors. You can also choose
            to delete this take.
          </Typography>
        ) : (
          <Typography variant="body1">
            Your upload is complete. When you are done, you can close the tab.
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}
