import { createContext, ReactNode } from 'react'
import {
  CreateLocalTrackOptions,
  ConnectOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  Room,
  TwilioError,
} from 'twilio-video'
import { Callback, ErrorCallback } from '../../constants/types'
import { SelectedParticipantProvider } from './useSelectedParticipant/useSelectedParticipant'

import useHandleRoomDisconnectionErrors from './useHandleRoomDisconnectionErrors/useHandleRoomDisconnectionErrors'
import useHandleOnDisconnect from './useHandleOnDisconnect/useHandleOnDisconnect'
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed/useHandleTrackPublicationFailed'
import useLocalTracks from './useLocalTracks/useLocalTracks'
import useRoom from './useRoom/useRoom'
import useScreenShareToggle from './useScreenShareToggle/useScreenShareToggle'
import { useAppState } from 'src/state'
import useDeviceIdSelection from './useDeviceIdSelection/useDeviceIdSelection'
import useBackgroundBlur from './useBackgroudBlur/useBackgroudBlur'
import useVirtualBackground from './useVirtualBackground/useVirtualBackground'

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface IVideoContext {
  room: Room
  audioTrackId: string
  updateAudioTrackId: (id: string) => void
  videoTrackId: string
  updateVideoTrackId: (id: string) => void
  localTracks: Array<LocalAudioTrack | LocalVideoTrack>
  isConnecting: boolean
  connect: (token: string) => Promise<void>
  onError: ErrorCallback
  onDisconnect: Callback
  getLocalVideoTrack: (
    newOptions?: CreateLocalTrackOptions
  ) => Promise<LocalVideoTrack>
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>
  isAcquiringLocalTracks: boolean
  removeLocalVideoTrack: () => void
  isSharingScreen: boolean
  toggleScreenShare: () => void
  screenShareStream: MediaStream | undefined // TODO: Changed to null?
  getAudioAndVideoTracks: () => Promise<void>
  blurBackground: boolean
  toggleBackgroundBlur: () => void
  virtualBackground: string | undefined // Should change to object later
  setVirtualBackground: (id: string) => void // Should change to object later
}

export const VideoContext = createContext<IVideoContext>(null!)

interface VideoProviderProps {
  isGroup: boolean
  options?: ConnectOptions
  onError: ErrorCallback
  onDisconnect?: Callback
  children: ReactNode
}

export function VideoProvider({
  isGroup,
  options,
  children,
  onError = () => {},
  onDisconnect = () => {},
}: VideoProviderProps) {
  const onErrorCallback = (error: TwilioError | Error) => {
    console.error(`ERROR: ${error.message}`, error)
    onError(error)
  }

  const { setIsFetching } = useAppState()
  const { blurBackground, toggleBackgroundBlur } = useBackgroundBlur()
  const { virtualBackground, setVirtualBackground } = useVirtualBackground()
  const { videoTrackId, audioTrackId, updateVideoTrackId, updateAudioTrackId } =
    useDeviceIdSelection()
  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  } = useLocalTracks(blurBackground, virtualBackground)
  const { room, isConnecting, connect } = useRoom(
    localTracks,
    onErrorCallback,
    setIsFetching,
    options
  )

  // Register onError and onDisconnect callback functions.
  useHandleRoomDisconnectionErrors(room, onError)
  useHandleTrackPublicationFailed(room, onError)
  useHandleOnDisconnect(room, onDisconnect)
  const { isSharingScreen, screenShareStream, toggleScreenShare } =
    useScreenShareToggle(isGroup, room, onError)

  return (
    <VideoContext.Provider
      value={{
        room,
        videoTrackId,
        audioTrackId,
        updateVideoTrackId,
        updateAudioTrackId,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        onDisconnect,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        isSharingScreen,
        toggleScreenShare,
        screenShareStream,
        getAudioAndVideoTracks,
        blurBackground,
        toggleBackgroundBlur,
        virtualBackground,
        setVirtualBackground,
      }}
    >
      <SelectedParticipantProvider room={room}>
        {children}
      </SelectedParticipantProvider>
    </VideoContext.Provider>
  )
}
