import { ChangeEvent, PropsWithChildren, useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Snackbar,
} from '@material-ui/core'
import * as Sentry from '@sentry/browser'
import { Alert } from '@material-ui/lab'


interface ReportDialogProps {
  open: boolean
  onClose(): void
}

function ReportDialog({ open, onClose }: PropsWithChildren<ReportDialogProps>) {
  const snackbarHide = 5000
  const [errorMessage, setErrorMessage] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setErrorMessage(value)
  }

  const handleSubmit = () => {
    Sentry.captureException(errorMessage)
    setShowSuccess(true)
    onClose()
  }

  const handleSnackBarClose = () => setShowSuccess(false)

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Report Error</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide us with as much information as possible so we can try
            to fix the issue
          </DialogContentText>
          <TextField
            autoFocus
            id="error-message"
            label="Error description"
            type="text"
            fullWidth
            value={errorMessage}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            color="secondary"
            variant="contained"
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        key="success-snackbar-bottom-left"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={showSuccess}
        onClose={handleSnackBarClose}
        autoHideDuration={snackbarHide}
      >
        <Alert onClose={handleSnackBarClose} severity="success">
          Thank you for reporting the error!
        </Alert>
      </Snackbar>
    </>
  )
}

export default ReportDialog