import { useState, useCallback } from 'react'

export default function useDeviceIdSelection() {
  const [videoTrackId, setVideoTrackId] = useState('')
  const [audioTrackId, setAudioTrackId] = useState('')

  const updateVideoTrackId = useCallback((newId: string) => {
    setVideoTrackId(newId)
  }, [])

  const updateAudioTrackId = useCallback((newId: string) => {
    setAudioTrackId(newId)
  }, [])

  return {
    videoTrackId,
    audioTrackId,
    updateVideoTrackId,
    updateAudioTrackId,
  }
}
