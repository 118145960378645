import { PropsWithChildren, useState } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'
import ComputerIcon from '@material-ui/icons/Computer'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    container: {
      width: '500px',
    },
    button: {
      float: 'right',
    },
    dropZone: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    buttons: {
      margin: theme.spacing(2),
    },
  })
)

interface ScreenShareDialogProps {
  open: boolean
  onShare(value: boolean): void
  onCancel(): void
}

const TIPS_1 = 'Screen sharing is the easiest with 2 monitors'
const TIPS_2 = 'Screen sharing works best on a good computer'
const TIPS_3 =
  'A small dialogue box will appear once your screen is shared with the options to "Stop sharing" and "Hide". Please click "Hide", so it does not appear in your screen recording'

function ScreenShareDialog({
  open,
  onShare,
  onCancel,
}: PropsWithChildren<ScreenShareDialogProps>) {
  const classes = useStyles()
  const [showTips, setShowTips] = useState(true)

  const onScreenShareClicked = () => {
    onShare(showTips)
  }

  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      open={open}
      onClose={onCancel}
      className={classes.root}
    >
      <DialogTitle>Screen Sharing Tips</DialogTitle>
      <DialogContent className={classes.container}>
        <List>
          <ListItem>
            <ListItemIcon>
              <DesktopWindowsIcon />
            </ListItemIcon>
            <ListItemText
              id="switch-list-label-two-monitors"
              primary={TIPS_1}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ComputerIcon />
            </ListItemIcon>
            <ListItemText
              id="switch-list-label-good-computer"
              primary={TIPS_2}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <VisibilityOffIcon />
            </ListItemIcon>
            <ListItemText id="switch-list-label-hide-dialog" primary={TIPS_3} />
          </ListItem>

          <ListItem>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={!showTips}
                    onChange={(_, checked) => setShowTips(!checked)}
                    name="showTips"
                  />
                }
                label="Don't show these tips again"
              />
            </FormGroup>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions className={classes.buttons}>
        <Button
          className={classes.button}
          color="secondary"
          variant="outlined"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={onScreenShareClicked}
        >
          Share
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ScreenShareDialog
