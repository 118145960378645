import { useState, useEffect, useCallback } from 'react'
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'
import { useAppState } from 'src/state'
import { addParticipant, createUpload, setSingleHostValues, setGroupHostValues } from '@michaelprichardson/cloudpresent-core'
import type { Participant } from '@michaelprichardson/cloudpresent-core'

export default function useSetHostValues(isGroup: boolean) {
  const { participant, event, groupRecording, uploadId, setCurrentSession } =
    useAppState()
  const { room } = useVideoContext()
  const [isHost, setHost] = useState(false)
  const [hostValuesSet, setHostValuesSet] = useState(false)
  const [haveParticipant, setParticipant] = useState(false)

  useEffect(() => {
    // Only when the participant is set the figure out of they are the host
    if (!haveParticipant && participant && isGroup) {
      setHost(participant.id === groupRecording.host.id)
      setParticipant(true)
    } else if (!haveParticipant && participant && !isGroup) {
      setHost(true)
      setParticipant(true)
    }
  }, [participant, isGroup])

  useEffect(() => {
    const setupHostValues = async () => {
      // If there is a participant and we know if they are the host or not then either listen for updates or set the default
      if (haveParticipant && !hostValuesSet) {
        if (isHost && uploadId && groupRecording && room.sid) {
          // When the host joins then everything is reset
          await setGroupHostValues(event.id, groupRecording.id, uploadId)

          // Update the upload doc with the correct data
          await createUpload(uploadId, event.id, room.sid, true, groupRecording.id)

          // Add user to Firestore here
          await addParticipantToFirestore(uploadId, participant)

          setHostValuesSet(true)
        } else if (isHost && uploadId && !isGroup && room.sid) {
          // When the host joins then everything is reset
          await setSingleHostValues(event.id, uploadId)

          // Update the upload doc with the correct data
          await createUpload(uploadId, event.id, room.sid, false)

          // Add user to Firestore here
          await addParticipantToFirestore(uploadId, participant)
        } else if (!isHost && uploadId && groupRecording && room.sid) {
          // Add user to Firestore here
          await addParticipantToFirestore(uploadId, participant)
        }
      }
    }

    setupHostValues()
  }, [
    isHost,
    haveParticipant,
    uploadId,
    event,
    groupRecording,
    room,
    hostValuesSet,
    isGroup,
  ])

  const addParticipantToFirestore = async (
    uploadId: string,
    participant: Participant
  ) => {
    const currentSession = await addParticipant(uploadId, participant, room.localParticipant.sid)
    setCurrentSession(currentSession)
  }

  // Only the host can reset the recording
  const resetHostFields = useCallback(() => {
    setHost(false)
    setHostValuesSet(false)
    setParticipant(false)
  }, [])

  return { resetHostFields }
}
