import { useState, useEffect, useCallback } from 'react'
import { captureException } from '@sentry/react'
import { IUploadTask } from 'src/constants/types'
import { useAppState } from 'src/state'
import JSZip from 'jszip'
import { ZIPPING_TIME } from 'src/constants/global'
import { zeroPadding } from 'src/utils/text'
import { Uploader, addTakeToUpload } from '@michaelprichardson/cloudpresent-core'

const ASSET_INITIAL_PROGRESS: IUploadTask = {
  pending: false,
  processing: false,
  uploading: false,
  progress: 0,
  uploaded: 0,
  total: 0,
}

export default function useUpload(
  isGroup: boolean,
  isUsingScreenShare: boolean,
  uploading: boolean,
  setUploading: (value: boolean) => void,
  additionalNotes: string,
  assets: File[],
  recordingNumber: number,
  uploadId?: string
) {
  const { participant, event } = useAppState()
  const [startedUpload, setStartedUpload] = useState(false)
  const [uploadComplete, setUploadComplete] = useState(false)
  const [uploadDeleted, setUploadDeleted] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(ASSET_INITIAL_PROGRESS)
  const [uploadError, setUploadError] = useState<string>()

  // const [abortedUpload, setAbortedUpload] = useState(false)

  const uploader = Uploader.getInstance()

  useEffect(() => {
    uploader.addProgressListener((progress) => {
      setUploadProgress({
        pending: false,
        processing: false,
        uploading: true,
        progress: progress.percentage,
        uploaded: progress.completedSize,
        total: progress.totalSize,
      })
    })
  }, [uploader])

  useEffect(() => {
    if (uploadId && uploading && !startedUpload) {
      setStartedUpload(true)
      uploadToGroupStorage(
        isGroup,
        isUsingScreenShare,
        uploadId,
        event.id,
        participant.id,
        additionalNotes,
        assets
      )
    }
  }, [
    isGroup,
    isUsingScreenShare,
    uploadId,
    uploading,
    startedUpload,
    participant,
    event,
    additionalNotes,
    assets,
  ])

  const uploadToGroupStorage = async (
    isGroup: boolean,
    usingScreenShare: boolean,
    uploadTaskId: string,
    eventId: string,
    participantId: string,
    notes: string,
    assetsBlob: File[]
  ) => {
    try {
      // TODO: Figure out why this gets called twice
      if (startedUpload) {
        console.error('Already started an upload')
        return
      }

      const hasAssets = assetsBlob.length > 0
      await addTakeToUpload(
        uploadTaskId,
        notes,
        hasAssets,
        usingScreenShare,
        recordingNumber,
      )

      // Asset upload
      if (hasAssets) {
        // Set progress
        setUploadProgress({
          ...uploadProgress,
          ...{ pending: false, processing: true },
        })

        // Create a zipped folder
        const zippedBlob = await zipBlob(assets || [])

        const folder = isGroup ? 'group-recordings' : 'recordings'

        // Upload assets
        await uploader.upload({
          name: `${zeroPadding(recordingNumber)}_${uploadTaskId}-assets`,
          path: `${folder}/originals/${eventId}/${participantId}/${uploadTaskId}/${zeroPadding(
            recordingNumber
          )}_assets.zip`,
          contentType: 'application/zip',
          data: zippedBlob,
        })
      }

      setUploadComplete(true)
    } catch (error: any) {
      if (error.message) {
        setUploadError(error.message)
      } else {
        setUploadError('unknown')
      }
      resetUpload()
      captureException(error)
    }
  }


  // TODO: Can move this into core
  const zipBlob = (files: File[], maxTime = ZIPPING_TIME): Promise<Blob> => {
    const zipping: Promise<Blob> = new Promise(async (resolve, reject) => {
      const zip = new JSZip()
      for (const blob of files) {
        zip.file(blob.name, blob)
      }
      const zippedBlob = await zip.generateAsync({ type: 'blob' })
      resolve(zippedBlob)
    })
    const zipTimeout: Promise<Blob> = new Promise((resolve, reject) =>
      setTimeout(() => {
        console.error(new Error('Failed to zip files'))
        reject(new Error('zipping-timeout'))
      }, maxTime)
    )
    return Promise.race([zipping, zipTimeout])
  }

  // Only the host can reset the recording
  const deleteUpload = useCallback(async () => {
    if (uploadId && recordingNumber) {
      await addTakeToUpload(
        uploadId,
        'notes',
        false,
        false,
        recordingNumber,
        true
      )
    }
    setUploadComplete(true)
    setUploadDeleted(true)
  }, [uploadId, recordingNumber])

  // Only the host can reset the recording
  const resetUpload = useCallback(() => {
    setUploading(false)
    setStartedUpload(false)
    setUploadComplete(false)
    setUploadDeleted(false)
    setUploadProgress(ASSET_INITIAL_PROGRESS)
  }, [])

  // Reset uploads error
  const resetUploadError = () => {
    resetUpload()
    setUploadError(undefined)
  }

  return {
    uploadProgress,
    uploadComplete,
    uploadDeleted,
    uploadError,
    deleteUpload,
    resetUploadError,
    resetUpload,
  }
}
