import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import * as ReactDOM from 'react-dom'
import { App } from './App'
import { initializePlatform } from '@michaelprichardson/cloudpresent-core'
// @ts-ignore
import packageJson from '../package.json'
import './index.css'

initializePlatform(
  {
    apiKey: process.env.REACT_APP_FB_API_KEY!,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN!,
    databaseURL: process.env.REACT_APP_FB_DATABASE_URL!,
    projectId: process.env.REACT_APP_FB_PROJECT_ID!,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET!,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_ID!,
    appId: process.env.REACT_APP_FB_APP_ID!,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID!,
  },
  {
    region: process.env.REACT_APP_AWS_REGION!,
    bucket: process.env.REACT_APP_AWS_BUCKET!,
    identityPoolId: process.env.REACT_APP_AWS_IAM_POOL!,
    maxAttempts: 5,
  },
  {
    dsn: 'https://c1e089ab43634aefa96bee6e42e95583@o510792.ingest.sentry.io/5606973',
    environment: process.env.REACT_APP_SENTRY_ENV || 'production',
    release: packageJson.version,
  },
  !!process.env.REACT_APP_ENV
)

ReactDOM.render(<App />, document.getElementById('root'))

// registerServiceWorker()
