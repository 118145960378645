import { Grid } from '@material-ui/core'
import Lottie from 'lottie-react'

// @ts-ignore
import notFoundAnimation from 'src/assets/lottie-not-found.json'
import Title from '../Title/Title'

export const NotFound = () => {
  return (
    <>
      <Title
        title="Not Found | Cloud Present"
        description="The page you are looking for does not exist"
      ></Title>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={3}>
          <Lottie animationData={notFoundAnimation} loop={false} autoPlay />
        </Grid>
      </Grid>
    </>
  )
}
