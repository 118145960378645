import React, { useEffect, useState, createRef, ChangeEvent } from 'react'
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Slider,
} from '@material-ui/core'
import {
  Stop as StopIcon,
  PlayArrow as PlayArrowIcon,
} from '@material-ui/icons'
import useUploadingContext from 'src/hooks/useUploadingContext/useUploadingContext'
import {
  createStyles,
  makeStyles,
  Theme,
  styled,
} from '@material-ui/core/styles'
import { formatTime } from 'src/utils/time'

const Video = styled('video')({
  width: '100%',
  height: '100%',
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    dialogContainer: {
      width: '75vw',
    },
    container: {
      position: 'relative',
      height: 0,
      overflow: 'hidden',
      paddingTop: `${(9 / 16) * 100}%`,
      background: theme.palette.black.main,
      margin: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        height: 90,
        width: `${(90 * 16) / 9}px`,
        marginRight: '8px',
        marginBottom: '0',
        fontSize: '10px',
        paddingTop: `${90 - 2}px`,
      },
    },
    innerContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    videoContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(40, 42, 43, 0.75)',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
    },
  })
)

function PreviewVideoContainer({ children }: { children: React.ReactNode }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.videoContainer}>{children}</div>
      </div>
    </div>
  )
}

function PreviewDialog() {
  const classes = useStyles()
  const { previewDialog, closePreviewDialog, cameraBlob, screenBlob, counter } =
    useUploadingContext()

  const [play, setPlay] = React.useState(false)
  const [previewCurrent, setPreviewCurrent] = React.useState(0)
  const [previewDuration, setPreviewDuration] = React.useState(0)
  const [previewCurrentTime, setPreviewCurrentTime] = React.useState('00:00')
  const [previewDurationTime, setPreviewDurationTime] = React.useState('00:00')
  const cameraPreviewVideoRef = createRef<HTMLVideoElement>()
  const [cameraPreviewVideoSrc, setCameraPreviewVideoSrc] = useState<string>()
  const screenPreviewVideoRef = createRef<HTMLVideoElement>()
  const [screenPreviewVideoSrc, setScreenPreviewVideoSrc] = useState<string>()

  useEffect(() => {
    if (cameraBlob) {
      setCameraPreviewVideoSrc(window.URL.createObjectURL(cameraBlob))
    }

    if (screenBlob) {
      setScreenPreviewVideoSrc(window.URL.createObjectURL(screenBlob))
    }

    if (counter) {
      setPreviewDuration(counter)
      setPreviewDurationTime(formatTime(counter * 1000))
    }

    return () => {
      setCameraPreviewVideoSrc(undefined)
      setScreenPreviewVideoSrc(undefined)
    }
  }, [cameraBlob, screenBlob])

  const onClosedClicked = () => {
    closePreviewDialog()
  }

  const handlePreviewControls = () => {
    if (play) {
      cameraPreviewVideoRef.current?.pause()
      screenPreviewVideoRef.current?.pause()
    } else {
      cameraPreviewVideoRef.current?.play()
      screenPreviewVideoRef.current?.play()
    }
    setPlay(!play)
  }

  const onVideoEnded = () => {
    setPlay(false)
    setPreviewCurrent(0)
    setPreviewCurrentTime('00:00')
  }

  const handlePreviewViewScrubbing = (
    _: ChangeEvent<{}>,
    newValue: number | number[]
  ) => {
    const time = newValue as number
    // Check each ref individually as there may not be a screen recording
    if (cameraPreviewVideoRef.current) {
      cameraPreviewVideoRef.current.currentTime = time
      setPreviewCurrent(time)
    }
    if (screenPreviewVideoRef.current) {
      screenPreviewVideoRef.current.currentTime = time
    }
  }

  const onVideoTimeUpdated = () => {
    const time = cameraPreviewVideoRef.current?.currentTime
    if (time) {
      setPreviewCurrent(time)
      setPreviewCurrentTime(formatTime(time * 1000))
    }
  }

  return (
    <Dialog
      disableBackdropClick={previewDialog}
      disableEscapeKeyDown={previewDialog}
      maxWidth="xl"
      open={previewDialog}
      onClose={onClosedClicked}
      className={classes.root}
    >
      <DialogTitle>Preview</DialogTitle>
      <DialogContent className={classes.dialogContainer}>
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          {cameraPreviewVideoSrc && (
            <Grid item md={6} xs={5}>
              <PreviewVideoContainer>
                <Video
                  style={{ objectFit: 'contain', transform: 'rotateY(180deg)' }}
                  src={cameraPreviewVideoSrc}
                  ref={cameraPreviewVideoRef}
                  onEnded={() => onVideoEnded()}
                  onTimeUpdate={() => onVideoTimeUpdated()}
                />
              </PreviewVideoContainer>
            </Grid>
          )}

          {screenPreviewVideoSrc && (
            <Grid item md={6} xs={5}>
              <PreviewVideoContainer>
                <Video
                  style={{ objectFit: 'contain' }}
                  src={screenPreviewVideoSrc}
                  ref={screenPreviewVideoRef}
                />
              </PreviewVideoContainer>
            </Grid>
          )}

          <Grid item md={12}>
            <Slider
              value={previewCurrent}
              min={0}
              max={previewDuration}
              onChange={handlePreviewViewScrubbing}
              aria-labelledby="preview-video-slider"
            />
          </Grid>

          <Grid item md={12}>
            <Grid container justifyContent="center">
              <Grid item md={4}>
                <Typography
                  variant="body1"
                  display="block"
                  gutterBottom
                  style={{ textAlign: 'center' }}
                >
                  {`${previewCurrentTime}/${previewDurationTime}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12}>
            <Grid container justifyContent="center">
              <Grid item>
                {play ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePreviewControls}
                    fullWidth
                    startIcon={<StopIcon />}
                  >
                    Stop
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePreviewControls}
                    fullWidth
                    startIcon={<PlayArrowIcon />}
                  >
                    Play
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClosedClicked}
          color="primary"
          variant="contained"
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PreviewDialog
