import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_VIDEO_INPUT_KEY,
} from 'src/constants/video'
import {
  FormControl,
  MenuItem,
  Typography,
  Select,
  FormHelperText,
} from '@material-ui/core'
import { LocalVideoTrack } from 'twilio-video'
import useMediaStreamTrack from 'src/hooks/useMediaStreamTrack/useMediaStreamTrack'
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'
import { useVideoInputDevices } from 'src/hooks/deviceHooks/deviceHooks'
import useLocalVideoToggle from 'src/hooks/useLocalVideoToggle/useLocalVideoToggle'

export default function VideoInputList() {
  const videoInputDevices = useVideoInputDevices()
  const { localTracks, updateVideoTrackId } = useVideoContext()
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle()

  const localVideoTrack = localTracks.find(
    (track) => track.kind === 'video'
  ) as LocalVideoTrack
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack)
  const localVideoInputDeviceId =
    mediaStreamTrack?.getSettings().deviceId ||
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)

  function replaceTrack(newDeviceId: string) {
    updateVideoTrackId(newDeviceId)
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId)

    if (isVideoEnabled) {
      localVideoTrack.restart({
        ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
        deviceId: { exact: newDeviceId },
      })
    } else {
      toggleVideoEnabled()
    }
  }

  return (
    <div>
      {videoInputDevices.length > 1 ? (
        <FormControl fullWidth>
          <Typography variant="subtitle2" gutterBottom>
            Video Input
          </Typography>
          <Select
            onChange={(e) => replaceTrack(e.target.value as string)}
            value={localVideoInputDeviceId || ''}
            variant="outlined"
          >
            {videoInputDevices.map((device) => (
              <MenuItem value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            Select the camera you would like to use
          </FormHelperText>
        </FormControl>
      ) : (
        <>
          <Typography variant="subtitle2" gutterBottom>
            Video Input
          </Typography>
          <Typography>
            {localVideoTrack?.mediaStreamTrack.label || 'No Local Video'}
          </Typography>
        </>
      )}
    </div>
  )
}
