import { useState, useEffect, useCallback } from 'react'
import { useAppState } from 'src/state'
import { RecordingState } from 'src/constants/types'
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'
import { COUNT_DOWN_DURATION } from 'src/constants/global'
import { addRecordingTimeCode, attachRecordingListener, cancelCountDown, detachRecordingListener, incrementTakes, Logger, startCountDown, startRecording, stopRecording, toggleTwilioRecording } from '@michaelprichardson/cloudpresent-core'

export default function useRecording(isGroup: boolean) {
  const category = 'useRecording'

  const { participant, event, groupRecording, uploadId } = useAppState()
  const { isSharingScreen, room } = useVideoContext()
  const [isHost, setHost] = useState(false)
  const [isCountingDown, setCountingDown] = useState(false)
  const [isRecording, setRecording] = useState(false)
  const [recordingState, setRecordingState] = useState(
    RecordingState.NOT_STARTED
  )
  const [haveParticipant, setHaveParticipant] = useState(false)
  const [isUsingScreenShare, setUsingScreenShare] = useState(false)

  const [recordingNumber, setRecordingNumber] = useState(0)

  const [resetTimer, setResetTimer] = useState(false)
  const [countDownTimerRef, setCountDownTimerRef] = useState<NodeJS.Timeout>()

  useEffect(() => {
    // Only when the participant is set the figure out of they are the host
    if (!haveParticipant && participant && isGroup) {
      setHost(participant.id === groupRecording.host.id)
      setHaveParticipant(true)
    } else if (!haveParticipant && participant && !isGroup) {
      setHost(true)
      setHaveParticipant(true)
    }
  }, [participant, haveParticipant, groupRecording, isGroup])

  useEffect(() => {
    const setupRecording = async () => {
      // If there is a participant and we know if they are the host or not then either listen for updates or set the default
      if (haveParticipant) {
        attachRecordingListener(isGroup, event.id, isGroup ? groupRecording.id : uploadId!, (data: any) => {
          if (data) {
            // Check if host is recording
            const countDown = data.countDown || false
            const recording = data.recording || false
            const take = data.take || 0

            setCountingDown(countDown)
            setRecording(recording)
            setRecordingNumber(take)

            if (recording) {
              setResetTimer(false)
            }
          } else {
            setCountingDown(false)
            setRecording(false)
          }
        })
      }
    }

    setupRecording()

    return () => {
      // Detach the listener if there is one
      if (haveParticipant) {
        detachRecordingListener(isGroup, event.id, isGroup ? groupRecording.id : uploadId!)
      }
    }
  }, [haveParticipant, event, groupRecording, uploadId, isGroup])

  useEffect(() => {
    if (isRecording && recordingState === RecordingState.NOT_STARTED) {
      setRecordingState(RecordingState.RECORDING)
    } else if (!isRecording && recordingState === RecordingState.RECORDING) {
      setRecordingState(RecordingState.HAVE_RECORDING)
    } else if (
      isRecording &&
      recordingState === RecordingState.HAVE_RECORDING
    ) {
      setRecordingState(RecordingState.RECORDING)
    }
  }, [isRecording, recordingState])

  useEffect(() => {
    if (isRecording && isSharingScreen) {
      setUsingScreenShare(true)
    }
  }, [isRecording, isSharingScreen])

  // Only the host can update the recording
  const toggleRecording = useCallback(() => {
    const updateRecording = async () => {
      // set the screen share flag when the host starts recording
      if (!isRecording) {
        setUsingScreenShare(isSharingScreen)
      }

      const docId = isGroup ? groupRecording.id : uploadId!

      // If starting to record
      if (!isRecording) {
        // Update database with count down
        await startCountDown(isGroup, event.id, docId)
        const countDownTimer = setTimeout(async () => {
          // Update database with recording
          await startRecording(isGroup, event.id, docId)

          // Add timestamp for recordings
          await addRecordingTimeCode(uploadId!, participant.id, recordingNumber + 1, true)

          // Increment the takes when recording ends
          await incrementTakes(isGroup, event.id, docId)

          // Start twilio recording
          await toggleTwilioRecording(room.sid, true)
        }, COUNT_DOWN_DURATION)

        setCountDownTimerRef(countDownTimer)
      } else {
        // Update database with recording
        await stopRecording(isGroup, event.id, docId)

        // Add timestamp for recordings
        await addRecordingTimeCode(uploadId!, participant.id, recordingNumber, false)

        await toggleTwilioRecording(room.sid, false)
      }
    }

    if (isHost) {
      updateRecording()
    }
  }, [
    isHost,
    isRecording,
    isSharingScreen,
    room,
    event,
    groupRecording,
    participant,
    uploadId,
    isGroup,
    recordingNumber,
  ])

  // Only the host can reset the recording
  const resetRecording = useCallback(() => {
    setRecording(false)
    setRecordingState(RecordingState.NOT_STARTED)
    setHaveParticipant(false)
    setUsingScreenShare(false)
  }, [])

  const resetTimerValue = useCallback(() => {
    setResetTimer(true)
  }, [])

  const cancelCountDownTimer = useCallback(() => {
    const cancelCountdown = async () => {
      if (countDownTimerRef) {
        clearTimeout(countDownTimerRef)
        setCountDownTimerRef(undefined)
      }

      const docId = isGroup ? groupRecording.id : uploadId!
      await cancelCountDown(isGroup, event.id, docId)
    }

    Logger.log(category, `Cancelling countdown ${isHost}`)
    if (isHost) {
      cancelCountdown()
    }
  }, [isHost, event, groupRecording, countDownTimerRef])

  return {
    isCountingDown,
    isRecording,
    toggleRecording,
    isUsingScreenShare,
    canUpload: recordingState === RecordingState.HAVE_RECORDING,
    resetRecording,
    recordingNumber,
    resetTimer,
    resetTimerValue,
    cancelCountDownTimer,
  }
}
