import { attachParticipantListeners, detachParticipantListeners } from '@michaelprichardson/cloudpresent-core'
import { useState, useEffect } from 'react'
import { useAppState } from 'src/state'

export default function useParticipants(isGroup: boolean) {
  const { participant, groupRecording } = useAppState()
  const [haveParticipant, setHaveParticipant] = useState(false)
  const [participants, setParticipants] = useState<
    Array<{ id: string; name: string }>
  >([])

  useEffect(() => {
    // Only when the participant is set the figure out of they are the host
    if (!haveParticipant && participant && isGroup) {
      setHaveParticipant(true)
    } else if (!haveParticipant && participant && !isGroup) {
      setHaveParticipant(true)
      setParticipants([
        {
          id: participant.id,
          name: participant.name,
        },
      ])
    }
  }, [participant, isGroup])

  useEffect(() => {
    const getParticipants = async () => {
      // If there is a participant and we know if they are the host or not then either listen for updates or set the default
      if (haveParticipant && groupRecording) {

        attachParticipantListeners(groupRecording.eventId, groupRecording.id, (participants) => {
          setParticipants(participants)
        })
      }
    }

    getParticipants()

    return () => {
      // Detach the listener if there is one
      if (haveParticipant && groupRecording) {
        detachParticipantListeners(groupRecording.eventId, groupRecording.id)
      }
    }
  }, [haveParticipant, groupRecording])

  return { participants }
}
