import { useAppState } from 'src/state'
import useConnectionOptions from 'src/utils/useConnectionOptions/useConnectionOptions'
import useCacheImages from 'src/hooks/useCacheImages/useCacheImages'

import { VideoProvider } from 'src/components/VideoProvider'
import { UploadingProvider } from 'src/components/UploadingProvider'
import UnsupportedBrowserWarning from 'src/components/UnsupportedBrowserWarning/UnsupportedBrowserWarning'
import ErrorDialog from 'src/components/ErrorDialog/ErrorDialog'
import RecordingContainer from 'src/components/RecordingContainer/RecordingContainer'
import Title from 'src/components/Title/Title'

export const VideoContainer = ({ isGroup }: { isGroup: boolean }) => {
  const { error, setError } = useAppState()
  const connectionOptions = useConnectionOptions()

  useCacheImages()

  return (
    <UnsupportedBrowserWarning isGroup={isGroup}>
      <VideoProvider
        isGroup={isGroup}
        options={connectionOptions}
        onError={setError}
      >
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <UploadingProvider onError={setError} isGroup={isGroup}>
          <Title />
          <RecordingContainer isGroup={isGroup} />
        </UploadingProvider>
      </VideoProvider>
    </UnsupportedBrowserWarning>
  )
}
