import { useState, useEffect } from 'react'
import { unixTime } from 'src/utils/time'

export default function useTimer(isRecording: boolean, resetTimer: boolean) {
  const [second, setSecond] = useState('00')
  const [minute, setMinute] = useState('00')
  const [counter, setCounter] = useState(0)
  const [startTime, setStartTime] = useState(0)

  // If reset timer is set to true and counter is non zero reset
  useEffect(() => {
    if (resetTimer && counter > 0) {
      setStartTime(0)
      setCounter(0)
      setSecond('00')
      setMinute('00')
    }
  }, [resetTimer])

  // Set start time when recording was started
  useEffect(() => {
    if (isRecording) {
      setStartTime(unixTime())
    }
  }, [isRecording])

  // Run the timer
  useEffect(() => {
    let intervalId: NodeJS.Timer

    if (isRecording) {
      intervalId = setInterval(() => {
        const currentTime = unixTime()
        const seconds = currentTime - startTime
        const secondCounter = seconds % 60
        const minuteCounter = Math.floor(seconds / 60)

        const computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter
        const computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter

        setSecond(computedSecond.toString())
        setMinute(computedMinute.toString())

        setCounter(seconds)
      }, 100)
    }

    return () => clearInterval(intervalId)
  }, [isRecording, startTime])

  return { timer: `${minute}:${second}`, counter }
}
