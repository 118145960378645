// This function is used to provide error titles to the user that are
// different than the error messages provided by the SDK.
export default function enhanceTitle(title = '', code?: number) {
  switch (code) {
    case 20101: // Invalid token error
      return 'Invalid Credentials'
    case 53001: // Internet error
      return 'No Internet Connection'
    default:
      return title
  }
}
