import { useState } from 'react'
import {
  styled,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import useVideoContext from 'src/hooks/useVideoContext/useVideoContext'
import useUploadingContext from 'src/hooks/useUploadingContext/useUploadingContext'
import useScreenShareParticipant from 'src/hooks/useScreenShareParticipant/useScreenShareParticipant'
import useClosingTabEvent from 'src/hooks/useClosingTabEvent/useClosingTabEvent'
import ParticipantsScreenGrid from '../ParticipantsScreenGrid/ParticipantsScreenGrid'
import ParticipantsNoScreenGrid from '../ParticipantsNoScreenGrid/ParticipantsNoScreenGrid'

import HostScreenShare from '../HostScreenShare/HostScreenShare'
import RecordingStoppedDialog from '../RecordingStoppedDialog/RecordingStoppedDialog'
import ConfirmUploadDialog from '../ConfirmUploadDialog/ConfirmUploadDialog'
import PreviewDialog from '../PreviewDialog/PreviewDialog'
import CountDownTimer from '../CountDownTimer/CountDownTimer'
import RecordingQualityNotification from '../RecordingQualityNotification/RecordingQualityNotification'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
    },
    screenTile: {
      width: `calc(100% - ${theme.spacing(2) * 2}px)`,
      height: `calc(100% - ${theme.spacing(2) * 2}px)`,
      overflow: 'hidden',
      background: 'black',
      margin: theme.spacing(2),
      borderRadius: theme.spacing(1),
    },
    participantNoScreenGrid: {
      width: '100%',
      height: `calc(100% - ${theme.spacing(2) * 2}px)`,
    },
    participantScreenGrid: {
      width: '100%',
      height: `calc(100% - ${theme.spacing(2) * 2}px)`,
    },
    gridContainer: {
      width: '100%',
      height: '100%',
      display: 'grid',
      gridGap: theme.spacing(4),
      padding: theme.spacing(4),
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
      gridTemplateRows: '1fr',
    },
    gridScreenContainer: {
      gridColumn: '1 / span 8',
      gridRow: '1',
    },
    gridParticipantContainer: {
      gridColumn: '9 / span 4',
      gridRow: '1',
    },
  })
)

const Container = styled('div')(({ theme }) => {
  return {
    position: 'absolute',
    height: `calc(100vh - ${theme.footerHeight * 2}px)`,
    width: '100vw',
    top: 0,
    left: 0,
    marginTop: `${theme.footerHeight}px`,
  }
})

export default function Room({ isGroup }: { isGroup: boolean }) {
  const classes = useStyles()
  const { isSharingScreen, room } = useVideoContext()
  const {
    isRecording,
    canUpload,
    uploadDialog,
    previewDialog,
    streamingUploadProgress,
  } = useUploadingContext()
  const { screenShareParticipant } = useScreenShareParticipant()
  const [showStartNotification, setShowStartNotification] = useState(true)

  // Check if can close tab
  useClosingTabEvent(isRecording || streamingUploadProgress.started, room)

  let gridContent = (
    <Grid item xs={12}>
      <Grid container className={classes.participantNoScreenGrid}>
        <ParticipantsNoScreenGrid />
      </Grid>
    </Grid>
  )

  if (isSharingScreen || screenShareParticipant) {
    gridContent = (
      <>
        <div className={classes.gridContainer}>
          <div className={classes.gridScreenContainer}>
            <HostScreenShare />
          </div>
          <div className={classes.gridParticipantContainer}>
            <ParticipantsScreenGrid />
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <CountDownTimer />

      <Container>
        <Grid container className={classes.container}>
          {gridContent}
        </Grid>
      </Container>

      {previewDialog && !isGroup && <PreviewDialog />}
      {canUpload && <RecordingStoppedDialog />}
      {uploadDialog && <ConfirmUploadDialog />}

      <RecordingQualityNotification
        open={showStartNotification}
        onClose={setShowStartNotification}
      />
    </>
  )
}
