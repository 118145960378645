// Convert time from milliseconds int to hh:mm:ss string
export function formatTime(
  milliseconds: number,
  milli: boolean = false
): string {
  const seconds = milliseconds / 1000
  const mm = seconds / 60
  const ss = seconds % 60
  const SSS = milliseconds % 1000

  return `${zeroPad(mm)}:${zeroPad(ss)}${milli ? `.${zeroPad(SSS, 3)}` : ''}`
}

// Add zero padding
export function zeroPad(num: number, size: number = 2) {
  let s = Math.floor(num).toString()
  while (s.length < size) {
    s = '0' + s
  }
  return s
}

export function unixTime(): number {
  return Math.floor(Date.now() / 1000)
}
