import { useState, useCallback } from 'react'

export default function useUploading() {
  const [startedUpload, setStartedUpload] = useState(false)
  const [isUploading, setUploading] = useState(false)

  const toggleUploading = useCallback((value: boolean) => {
    setStartedUpload(true)
    setUploading(value)
  }, [])

  return { startedUpload, isUploading, toggleUploading }
}
