import React from 'react'
import MUISnackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

interface SnackbarProps {
  headline: string
  message: string
  variant?: 'success' | 'error' | 'warning'
  open: boolean
  handleClose?: () => void
  autoHideDuration?: number
}

export default function Snackbar({
  headline,
  message,
  variant,
  open,
  handleClose,
  autoHideDuration,
}: SnackbarProps) {
  const handleOnClose = (
    _: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return
    }

    handleClose?.()
  }

  return (
    <MUISnackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleOnClose}
      autoHideDuration={autoHideDuration}
      message={`${headline} ${message}`}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  )
}
