import { PropsWithChildren } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import enhanceMessage from './enhanceMessage'
import { TwilioError } from 'twilio-video'
import { useAppState } from 'src/state'
import enhanceTitle from './enhanceTitle'

interface ErrorDialogProps {
  dismissError: () => void
  error: TwilioError | Error | any | null
}

function ErrorDialog({
  dismissError,
  error,
}: PropsWithChildren<ErrorDialogProps>) {
  const { message, code } = error || {}
  const enhancedTitle = enhanceTitle('Uh oh! Something Went Wrong', code)
  const enhancedMessage = enhanceMessage(message, code)
  const { invalidEvent } = useAppState()

  return (
    <Dialog
      disableBackdropClick={invalidEvent}
      disableEscapeKeyDown={invalidEvent}
      open={error !== null}
      onClose={() => dismissError()}
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle>{enhancedTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{enhancedMessage}</DialogContentText>
        <DialogContentText>
          If the issue persists, please get in touch with us{' '}
          <a href="mailto:hello@cloudpresent.co">hello@cloudpresent.co</a>
        </DialogContentText>
      </DialogContent>

      {!invalidEvent && (
        <DialogActions>
          <Button
            onClick={() => dismissError()}
            color="primary"
            variant="contained"
          >
            Dismiss
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default ErrorDialog
