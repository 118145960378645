import { useEffect } from 'react'

export default function useHeight() {
  useEffect(() => {
    cacheImages([
      // About logo
      '/images/full_logo_dark.png',
      // Grids
      '/images/rule_of_thirds_white.png',
    ])
  }, [])

  const cacheImages = async (images: string[]) => {
    const promises = await images.map((url, index) => {
      return new Promise((resolve, reject) => {
        const image = new Image()

        image.src = url
        // @ts-ignore
        image.onload = resolve(null)
        // @ts-ignore
        image.onerror = reject()
      })
    })

    await Promise.all(promises)
  }
}
