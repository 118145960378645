import { useContext } from 'react'
import { UploadingContext } from '../../components/UploadingProvider'

export default function useUploadingContext() {
  const context = useContext(UploadingContext)
  if (!context) {
    throw new Error(
      'useUploadingContext must be used within a UploadingProvider'
    )
  }
  return context
}
