import AppStateProvider from './state'
import * as routes from './constants/routes'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from './theme'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import { NotFound } from 'src/components/NotFound/NotFound'
import { VideoContainer } from 'src/components/VideoContainer/VideoContainer'
import { Background } from 'src/components/Background/Background'

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Background>
        <CssBaseline />
        <BrowserRouter>
          <AppStateProvider>
            <Switch>
              <Route
                exact={true}
                path={routes.NOT_FOUND}
                component={NotFound}
              />

              <Route exact={true} path={routes.RECORDING}>
                <VideoContainer isGroup={false} />
              </Route>

              <Route exact={true} path={routes.GROUP_RECORDING}>
                <VideoContainer isGroup={true} />
              </Route>

              <Redirect to={routes.NOT_FOUND} />
            </Switch>
          </AppStateProvider>
        </BrowserRouter>
      </Background>
    </ThemeProvider>
  )
}
