import { useState, useEffect, useCallback } from 'react'
import { IStreamingUploadProgress } from 'src/constants/types'
import { useAppState } from 'src/state'
import { zeroPadding } from 'src/utils/text'
import { ChunkUploader } from '@michaelprichardson/cloudpresent-core'

interface StreamUploadProps {
  cameraChunks: Blob[]
  screenChunks: Blob[]
  isRecording: boolean
  recordingNumber: number
}

export default function useStreamUpload({
  cameraChunks,
  screenChunks,
  isRecording,
  recordingNumber,
}: StreamUploadProps) {
  const { event, userId, uploadId, currentSession, groupRecording } =
    useAppState()

  const [numberChunks, setNumberChunks] = useState(0)
  const [numberUploadedChunks, setNumberUploadedChunks] = useState(0)
  const [streamingUploadProgress, setStreamingUploadProgress] =
    useState<IStreamingUploadProgress>({
      started: false,
      complete: false,
      progress: 0,
      uploaded: 0,
      total: 0,
    })

  const uploader = ChunkUploader.getInstance()

  useEffect(() => {
    uploader.addProgressListener((progress) => {
      setStreamingUploadProgress({
        started: true,
        complete: false,
        progress: progress.percentage,
        uploaded: progress.completedSize,
        total: progress.totalSize,
      })
    })

    uploader.addCompleteListener(() => {
      setStreamingUploadProgress({
        ...streamingUploadProgress,
        complete: true,
      })
    })
  }, [uploader, streamingUploadProgress])

  useEffect(() => {
    uploader.setRecording(isRecording)
  }, [isRecording, uploader])

  useEffect(() => {
    if (cameraChunks.length > 0) {
      const nChunk = cameraChunks.length - 1
      const paddedRecording = zeroPadding(recordingNumber)
      const paddedIndex = zeroPadding(nChunk)
      const paddedSession = zeroPadding(currentSession)
      const filename = `${paddedRecording}_${paddedIndex}_${paddedSession}_camera_${Date.now()}`

      uploader.addUploadTask({
        name: filename,
        path: `recording-chunks/originals/${event.id}/${userId}/${uploadId}/${filename}.webm`,
        contentType: 'video/webm',
        data: cameraChunks[nChunk],
        progressPath: groupRecording ? `groupRecordings/${event.id}/${groupRecording.id}/progress/${userId}` : undefined,
      })
      setNumberChunks((prev) => (prev += 1))
    }
  }, [cameraChunks])

  useEffect(() => {
    if (screenChunks.length > 0) {
      const nChunk = screenChunks.length - 1
      const paddedRecording = zeroPadding(recordingNumber)
      const paddedIndex = zeroPadding(nChunk)
      const paddedSession = zeroPadding(currentSession)
      const filename = `${paddedRecording}_${paddedIndex}_${paddedSession}_screen_${Date.now()}`
      const folderName = groupRecording ? 'group-recordings' : 'recordings'

      uploader.addUploadTask({
        name: filename,
        path: `${folderName}/originals/${event.id}/${userId}/${uploadId}/${filename}.webm`,
        contentType: 'video/webm',
        data: screenChunks[nChunk],
        progressPath: groupRecording ? `groupRecordings/${event.id}/${groupRecording.id}/progress/${userId}` : undefined,
      })
      setNumberChunks((prev) => (prev += 1))
    }
  }, [screenChunks])

  // Only the host can reset the recording
  const resetProgress = useCallback(() => {
    setNumberChunks(0)
    setNumberUploadedChunks(0)
    setStreamingUploadProgress({
      started: false,
      complete: false,
      progress: 0,
      uploaded: 0,
      total: 0,
    })
  }, [])

  return {
    numberChunks,
    numberUploadedChunks,
    streamingUploadProgress,
    resetProgress,
  }
}
