import { useState, useCallback } from 'react'

export default function usePreviewDialog() {
  const [previewDialog, setPreviewDialog] = useState(false)

  // Callback to toggle the dialog for the host
  const togglePreviewDialog = useCallback(() => {
    setPreviewDialog(!previewDialog)
  }, [previewDialog])

  const closePreviewDialog = useCallback(() => {
    setPreviewDialog(false)
  }, [])

  return { previewDialog, togglePreviewDialog, closePreviewDialog }
}
