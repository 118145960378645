import { useState } from 'react'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import StopRecordIcon from '@material-ui/icons/Stop'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useUploadingContext from 'src/hooks/useUploadingContext/useUploadingContext'
import { useAppState } from 'src/state'

export const START_RECORDING_TEXT = 'Start Recording'
export const STOP_RECORDING_TEXT = 'Stop Recording'
export const PAUSED_RECORDING_TEXT = 'Resume Recording'
export const RESTART_RECORDING_TEXT = 'Processing Recording'
export const SHARE_NOT_STARTED_TEXT =
  'You need to share your screen to start recording'
export const SCREEN_SHARING_DURING_RECORD_TEXT =
  'You will not be able to share you screen during recording'

interface ToggleRecordingButtonProps {
  icon?: boolean
  disabled?: boolean
  fullWidth?: boolean
}

export default function ToggleRecordingButton({
  icon,
  disabled,
  fullWidth,
}: ToggleRecordingButtonProps) {
  const { uploadId } = useAppState()
  const { isRecording, toggleRecording, canUpload } = useUploadingContext()
  const [confirmDialog, setConfirmDialog] = useState(false)

  // TODO: Disable if upload is in progress
  const isDisabled = disabled || (isRecording && !uploadId)

  // If a recording is in progress the show stop recording
  let buttonText = isRecording ? STOP_RECORDING_TEXT : START_RECORDING_TEXT

  // If the upload button is available then show restart recording
  buttonText = canUpload ? RESTART_RECORDING_TEXT : buttonText

  const recordingClicked = () => {
    toggleRecording()
  }

  const confirmRestartRecording = () => {
    setConfirmDialog(false)
    toggleRecording()
  }

  return (
    <Tooltip
      title={''}
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
    >
      <span>
        {icon ? (
          <IconButton
            size="medium"
            color="secondary"
            aria-label="recording"
            onClick={recordingClicked}
            disabled={isDisabled}
          >
            {isRecording ? <StopRecordIcon /> : <FiberManualRecordIcon />}
          </IconButton>
        ) : (
          <Button
            onClick={recordingClicked}
            disabled={isDisabled}
            startIcon={
              isRecording ? <StopRecordIcon /> : <FiberManualRecordIcon />
            }
            color="secondary"
            variant="contained"
            fullWidth={fullWidth}
          >
            {buttonText}
          </Button>
        )}

        {/* TODO: Move out of button */}
        <Dialog
          open={confirmDialog}
          onClose={() => setConfirmDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Restart Recording</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to restart your recording? Restarting your
              recording will clear the previous recording.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setConfirmDialog(false)}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={confirmRestartRecording}
              color="secondary"
              variant="contained"
            >
              Start Recording
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    </Tooltip>
  )
}
