import { useState, useEffect } from 'react'

// If you don't have a version of React that supports
// hooks, you can use a class-based version of this
// component in ProgressProviderUsingClass.js
export default function ProgressProvider({
  valueStart,
  valueEnd,
  children,
}: {
  valueStart: number
  valueEnd: number
  children: any
}) {
  const [value, setValue] = useState<number>(valueStart)
  useEffect(() => {
    setValue(valueEnd)
  }, [valueEnd])

  return children(value)
}
